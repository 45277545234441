import Dashboard from "../Pages/projectCostingAndManagement/pageLayout/dashboard/dashboard.js";
import FixedBidOutput from "../Pages/projectCostingAndManagement/pageLayout/fixedBidProjectsLists/fixedBidOutput.js"
import TmOutput from "../Pages/projectCostingAndManagement/pageLayout/tmProjectsLists/tmOutput.js";
import { path } from "./PathNames.js";
import EditProjectFixBid from "../Pages/projectCostingAndManagement/pageLayout/fixedBidProjectsLists/editProject/editProjectFixBid.js";
import EditProjectTM from "../Pages/projectCostingAndManagement/pageLayout/tmProjectsLists/editProject/editProjectTM.js";
import InvoiceList from "../Pages/projectCostingAndManagement/pageLayout/invoiceList/invoiceList.js";
import EditInvoice from "../Pages/projectCostingAndManagement/pageLayout/editInvoice/editInvoice.js";
import ProjectCosting from "../Pages/projectCostingAndManagement/pageLayout/projectCosting/projectCosting.js";
import ProjectCostBreakUp from "../Pages/projectCostingAndManagement/pageLayout/projectCostBreakup/projectCostBreakup.js";
import OverheadsDashboard from "../Pages/projectCostingAndManagement/pageLayout/overheadsDashboard/overheadsDashboard.js";
import ResourceMargin from "../Pages/projectCostingAndManagement/pageLayout/resourceMargin/resourceMargin.js";

import ProjectCostingFixBid from "../Pages/projectCostingAndManagement/pageLayout/fixedBidProjectsLists/projectCostingFixBid/projectCostingFixBid.js"
import ProjectCostBreakUpFixBid from "../Pages/projectCostingAndManagement/pageLayout/fixedBidProjectsLists/projectCostBreakupFixBid/projectCostBreakupFixBid.js";

import ProjectCostingTM from "../Pages/projectCostingAndManagement/pageLayout/tmProjectsLists/projectCostingTM/projectCostingTM.js";
import ProjectCostBreakUpTM from "../Pages/projectCostingAndManagement/pageLayout/tmProjectsLists/projectCostBreakupTM/projectCostBreakupTM.js";

const PcmRoute = [
  {
    path: path.pcm_dashboard,
    component: Dashboard,
  },
  // New
  {
    path: path.pcm_dashboard_fixedBidProjects,
    component: FixedBidOutput,
  },
  // New
  {
    path: path.pcm_tmProjects,
    component: TmOutput,
  },
  // {
  //   path: path.pcm_editProject,
  //   component: EditProject,
  // },
  // New
  {
    path: path.pcm_editProjectFixBid,
    component: EditProjectFixBid,
  },
  // New
  {
    path: path.pcm_editProjectTM,
    component: EditProjectTM,
  },
  {
    path: path.pcm_invoices,
    component: InvoiceList,
  },
  {
    path: path.pcm_fixBid_invoices,
    component: InvoiceList,
  },
  { 
    path: path.pcm_tm_invoices,
    component: InvoiceList,
  },
  // { path: path.pcm_editInvoice, component: EditInvoice },
  // New
  {
    path: path.pcm_editInvoiceFixBid, 
    component: EditInvoice
  },
  // New
  {
    path: path.pcm_editInvoiceTM, 
    component: EditInvoice
  },
  // { path: path.pcm_addInvoice, component: EditInvoice },
  // new
  { 
    path: path.pcm_addInvoiceFixBid, 
    component: EditInvoice 
  },
  // New
  { 
    path: path.pcm_addInvoiceTM, 
    component: EditInvoice 
  },
  { 
    path: path.pcm_projectCostingInformation, 
    component: ProjectCosting 
  },
  // New
  { 
    path: path.pcm_projectCostingInformationFixBid, 
    component: ProjectCostingFixBid 
  },
  // New
  {
    path: path.pcm_projectCostBreakUpFixBid,
    component: ProjectCostBreakUpFixBid,
  },
  // New
  {
    path: path.pcm_projectCostingInformationTM,
    component: ProjectCostingTM
  },
  // New
  {
    path: path.pcm_projectCostBreakUpTM,
    component: ProjectCostBreakUpTM
  },
  { path: path.pcm_projectCostBreakUp, component: ProjectCostBreakUp },
  {
    path: path.pcm_overheads,
    component: OverheadsDashboard ,
  },
  {
    path: path.pcm_resourceMargin,
    component: ResourceMargin ,
  },
];

// Now 'routes' is an array of JSON objects with path and component properties.

export default PcmRoute;
