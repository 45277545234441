import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import style from "./CommentHistory.module.css";
import AddComment from "../AddComment/AddComment";
import deleteImg from "../../../../../assets/icons8-delete-24.png";
import editImg from "../../../../../assets/icons8-edit-30.png";
import {
  getAllCandidateComments,
  updateCandidateComment,
  candidateCommentMST,
  addCommentsForCandidate
} from "../../../../../Utils/axios/api";
import { useMutation, useQuery } from "react-query";
import moment from "moment";
import { notifySuccess, notifyError } from "../../../utils/notify";
import { employeeId } from "../../../../../Utils/constants";
import { Modal } from "react-responsive-modal";
import ATSButton from "../../../customTooltip/ATSButton";

const getCommentForCandidate = (candidateId) => {
  const existingComments = JSON.parse(localStorage.getItem('unsavedComments1')) || [];
  const commentData = existingComments.find(c => c.candidateId === candidateId);
  return commentData ? commentData.comment : '';
};

function CommentHistory({ candidateId, setShowCommentModal, closeModal, positionId, myCandidateName, myClientName, handleAddComment, getCommentForPosition }) {
  console.log("Step 1 : Rendering CommentHistory for candidateId:", candidateId);
  const [obj, setObj] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const { data: CommentData, isLoading: commentLoading, refetch: refetchComments } = useQuery(
    ["getAllCandidateComments", candidateId],
    () => getAllCandidateComments(candidateId),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
  console.log("Step 2 : Fetching all candidate comments...");

  const { data: preDefinedComment, isLoading: preDefinedCommentLoading, refetch: refetchPreDefinedComment } = useQuery(
    ["candidateCommentMST"], candidateCommentMST, 
    { refetchOnWindowFocus: false, retry: false }
  );
  console.log("Step 3: Fetching predefined comments...");

  const updateCandidateCommentMutation = useMutation(updateCandidateComment, {
    onMutate: () => { },
    onSuccess: () => {
      notifySuccess("Comment Deleted Successfully");
      console.log("Step 14: Comment deleted successfully");
      refetchComments();
      console.log("Step 15: Comments refreshed after deletion");
    },
    onError: (err) => {console.error("Error deleting comment:", err)},
  });

  const [showAddComment, setShowAddComment] = useState(false);

  useEffect(() => {
    console.log("Step 4: Checking for unsaved comments...");
    const unsavedComment = getCommentForCandidate(candidateId);
    if (unsavedComment) {
      console.log("Step 5: Unsaved comment found, showing AddComment component");
      setShowAddComment(true);
    }
  }, [candidateId]);

  const handleAddComments = () => {
    console.log("Step 6: Add Comment button clicked");
    setShowAddComment(true);
    setObj(null);
  };

  const handleEditComment = (comment) => {
    console.log("Step 7: Editing comment:", comment);
    setShowAddComment(true);
    setObj(comment);
  };

  const addCommentsForCandidateMutation = useMutation(addCommentsForCandidate, {
    onMutate: () => {
      console.log("Step 8: Mutation started for adding comment...");
    },
    onSuccess: (res) => {
      if (res.responseStatus.statusCode === 1) {
        console.log("Step 9: Trying to add Comment:", res.responseData);
        notifySuccess("Comment Added Successfully!");
        refetchComments();  // Refresh the comments list
        console.log("Step 10: Comments Added Successfully & Refetched Done");
        setShowAddComment(false);
        
      } else {
        notifyError(res.responseData.message);
      }
    },
    onError: (err) => {
      console.error("Step 11: Error adding comment:", err)
      notifyError("Something went wrong");
    }
  });

  const handlePredefinedCommentClick = (commentText) => {
    console.log("Step 12: Adding predefined comment:", commentText);
    addCommentsForCandidateMutation?.mutate({
      comment: commentText,
      employeeId: employeeId,
      id: candidateId,
    });
  };

  const deleteComment = () => {
    if (selectedComment) {
      updateCandidateCommentMutation?.mutate({
        commentId: selectedComment?.commentId,
        empId: selectedComment?.employeeId,
      });
      console.log("Step 13: Deleting comment:", selectedComment);
      setObj(null);
      handleAddComment(candidateId, "");
      setShowDeleteModal(false);
    }
  };

  const cancelDelete = () => {
    console.log("Cancel button clicked, closing delete modal");
    setShowDeleteModal(false);
    setSelectedComment(null);
  };

  const handleDeleteComment = (comment) => {
    setShowDeleteModal(true);
    setSelectedComment(comment);
    console.log("Delete button clicked for comment:", comment);
  };

  // useEffect(() => {
  //   setShowAddComment(false);
  // },[CommentData])

  return (
    <div>
      <DeleteConfirmationModel
        showDeleteModal={showDeleteModal}
        deleteComment={deleteComment}
        cancelDelete={cancelDelete}
      />
      <div style={{ display: "flex", alignItems: 'flex-start', gap: "1rem"}}>
        <div style={{width: "79%"}}>
            <div className={style.addButton}>
              {!showAddComment && (
                <button className={style.add_btn} onClick={handleAddComments}>
                  Add Comment
                </button>
              )}
            </div>
            {showAddComment && (
              <AddComment
                refetchComments={refetchComments}
                candidateId={candidateId}
                showAddComment={showAddComment}
                setShowAddComment={setShowAddComment}
                obj={obj}
                setObj={setObj}
                closeModal={closeModal}
                setShowCommentModal={setShowCommentModal}
                positionId={positionId}
                handleAddComment={handleAddComment}
                getCommentForPosition={getCommentForPosition}
                addCommentsForCandidateMutation={addCommentsForCandidateMutation}
              />
            )}
          <div className={style.description_box1} style={{marginTop: "0.5rem"}}>
            <div style={{paddingBottom: "0.3rem"}}>
              <div className={style.span2}>
                {myCandidateName === undefined && myClientName === undefined ? (
                  <p></p>
                ) : myCandidateName && myClientName ? (
                  <p>{myCandidateName}</p>
                ) : (
                  <p>{myCandidateName}</p>
                )}
              </div>
              <div className={style.maindivContent}></div>
              <div
                className={style.original_table}
                style={{
                  backgroundColor: "white",
                  minHeight: "3rem",
                  marginTop: "7px",
                }}
                >
                <>
                  {!commentLoading && CommentData?.length > 0 ? (
                    <Table borderless size="sm" style={{ fontSize: "14.5px" }}>
                      <thead
                        style={{
                          backgroundColor: "#F0F0F0",
                          position: "sticky",
                          top: "-5px",
                        }}
                      >
                        <tr>
                          <th style={{ padding: "10px", width: "3.5rem" }}>Date</th>
                          <th style={{ padding: "10px", minWidth: "100px" }}>Added By</th>
                          <th style={{ padding: "10px" }}>Comment</th>
                          <th style={{ padding: "10px", minWidth: "75px" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {CommentData.map((comment, index) => (
                          <tr key={index}>
                            <td style={{ paddingLeft: "10px", minWidth: "4rem" }}>
                              {comment?.created
                                ? moment(comment.created).year() === moment().year()
                                  ? moment(comment.created).format("DD MMM")
                                  : moment(comment.created).format("DD MMM YYYY")
                                : null}
                            </td>
                            <td style={{ paddingLeft: "10px", minWidth: "170px", maxWidth: "170px" }}>
                              {comment?.employeeName}
                            </td>
                            <td style={{ paddingLeft: "10px", whiteSpace: "pre-wrap", overflowWrap: "anywhere" }}>
                              {comment?.comment}
                            </td>
                            {comment.employeeId === employeeId ? (
                              <td style={{ paddingLeft: "10px", minWidth: "75px" }}>
                                <img
                                  src={editImg}
                                  height="20px"
                                  width="20px"
                                  alt="edit"
                                  style={{ cursor: "pointer" }}
                                  title="Edit Comment"
                                  onClick={() => handleEditComment(comment)}
                                  onKeyDown={() => handleEditComment(comment)}
                                  tabIndex={0}
                                />
                                <img
                                  src={deleteImg}
                                  alt="delete"
                                  height="22px"
                                  width="35px"
                                  style={{ cursor: "pointer", paddingLeft: "10px" }}
                                  title="Delete Comment"
                                  onClick={() => {
                                    handleDeleteComment(comment);
                                  }}
                                  onKeyDown={() => handleDeleteComment(comment)}
                                  tabIndex={0}
                                />
                              </td>
                            ) : (
                              <td style={{ paddingLeft: "10px", minWidth: "75px" }}></td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <>
                      <Table borderless size="sm" style={{ fontSize: "14.5px" }}>
                        <thead
                          style={{
                            backgroundColor: "#F0F0F0",
                            position: "sticky",
                            top: "-5px",
                          }}
                        >
                          <tr>
                            <th style={{ padding: "10px", width: "3.5rem" }}>Date</th>
                            <th style={{ padding: "10px", minWidth: "100px" }}>Added By</th>
                            <th style={{ padding: "10px" }}>Comment</th>
                            <th style={{ padding: "10px", minWidth: "75px" }}>Action</th>
                          </tr>
                        </thead>
                      </Table>
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: "14px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        No Comments Found
                      </p>
                    </>
                  )}
                </>

              </div>
              {/* <div className={style.addButton}>
                {!showAddComment && (
                  <button className={style.add_btn} onClick={handleAddComments}>
                    Add Comment
                  </button>
                )}
              </div> */}
            </div>
          </div>
        </div>
        <div style={{width: "21%", overflowY: "auto", display: "flex", flexWrap: 'wrap', gap: "0.5rem", position: "sticky", top: "0", }}>
          {preDefinedCommentLoading ? (<div>Loading...</div>) : (
            preDefinedComment && preDefinedComment.map((comment, index) => (<div key={index} style={{ flexBasis: "100%", display: "flex", justifyContent: "start" }}>
              <ATSButton onClick={() => handlePredefinedCommentClick(comment)}
                styles={{backgroundColor:"white", color:"black", fontSize:"13px", borderRadius:"20px", textAlign:"start", border:"2px solid #65a15e"}}>{comment}<br/>
              </ATSButton>
            </div>))
          )}
        </div>
      </div>
    </div>
  );
}

const DeleteConfirmationModel = ({
  showDeleteModal,
  cancelDelete,
  deleteComment,
}) => {
  return (
    <Modal
      open={showDeleteModal}
      onClose={() => {
        showDeleteModal(false);
      }}
      showCloseIcon={false}
      center
    >
      <div className={style.deleteModal}>
        <div> Do you want to delete this comment ?</div>
        <div className={style.btnRow}>
          <button className={style.yes_btn} onClick={deleteComment}>
            Yes
          </button>
          <button className={style.no_btn} onClick={cancelDelete}>
            No
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CommentHistory;
