import SideBar from "../sidebar/sidebar";
import { useLocation, useHistory } from "react-router-dom";
import style from "./editInvoice.module.css";
import { path } from "../../../../routes/PathNames";
import { upperCase } from "lodash";
import DatePicker from "react-datepicker";
import Moment from "moment/moment";
import React from "react";
import calendarIcon from "../../../../assets/calendarIcon.png";
import { useMutation } from "react-query";
import { addEditInvoice } from "../../../../Utils/axios/pcm_Api";
import {
  employeeId,
  projectCostingAndMarginAccessCode,
} from "../../../../Utils/constants";
import { useState, useEffect } from "react";
import { notifyError, notifySuccess } from "../../../atsDashboard/utils/notify";
import moment from "moment/moment";
import useValidationAccess from "../../../custumHooks/useValidationAccess";

export default function EditInvoice() {
  const { checkValidateAccess } = useValidationAccess();
  return (
    <div style={{ display: "flex" }}>
      {(checkValidateAccess(
        projectCostingAndMarginAccessCode?.create_invoice
      ) ||
        checkValidateAccess(
          projectCostingAndMarginAccessCode?.edit_invoice
        )) && (
        <>
          <SideBar /> <Body />
        </>
      )}
    </div>
  );
}

function Body() {
  const location = useLocation();
  const history = useHistory();
  const id = location?.state?.id;
  const edit = location?.state?.edit;
  const add = location?.state?.add;
  const preFillData = location?.state?.preFillData;
  const invoiceId = preFillData?.invoiceId;
  const currentPage = location?.state?.currentPage;
  const projectName = location?.state?.projectName;
  const [invoiceData, setInvoiceData] = useState(null);
  const [error, setError] = useState({
    invoiceDt: false,
    invoiceNo: false,
    moneyReceivedDt: false,
    extraMonth: false
  });

  const addEditInvoiceMutation = useMutation(addEditInvoice, {
    retry: false,
    onSuccess: (res) => {
      if (res?.message) {
        notifySuccess(res?.responseData);
        setTimeout(() => {
          handleRedirectInvoiceList();
        }, 0);
      } else {
        notifyError(res?.responseData?.message);
      }
    },
    onError: (err) => {
      notifyError(err);
    },
  });

  const handleRedirectInvoiceList = () => {
    if(location.pathname.includes("fix"))
      setTimeout(() => {
        history.push(path.pcm_fixBid_invoices, {
          id,
          currentPage,
          projectName,
          pccurrentPage: location?.state?.pccurrentPage,
        });
      }, 0);
    else
      setTimeout(() => {
        history.push(path.pcm_tm_invoices, {
          id,
          currentPage,
          projectName,
          pccurrentPage: location?.state?.pccurrentPage,
        });
      }, 0);
  };

  const handleRedirectProjectsList = () => {
    setTimeout(() => {
      history.push(location.pathname.includes('fix') ? path.pcm_dashboard_fixedBidProjects : path.pcm_tmProjects, {
        id,
        pccurrentPage: location?.state?.pccurrentPage,
      });
    }, 0);
  };

  const handleRedirectProjectCosting = () => {
    setTimeout(() => {
      history.push(
        location.pathname.includes('fix') ? path.pcm_projectCostingInformationFixBid : path.pcm_projectCostingInformationTM, {
        id,
        projectName,
        pccurrentPage: location?.state?.pccurrentPage,
      });
    }, 0);
  };

  const handleInvoiceDateFormat = (tempDate) => {
    return (
      moment(tempDate).format("YYYY-MM-DDTHH:mm:ss").toString().split("T")[0] +
      "T06:30:00"
    );
  };

  const handleAddEditInvoice = () => {
    if (
      !invoiceData?.invoiceRaisedDate ||
      !invoiceData?.invoiceAmount ||
      !invoiceData?.invoiceNumber || 
      !invoiceData?.invoiceMonth || 
      !invoiceData?.invoiceYear
    ) {
      const tempErr = {};
      if (!invoiceData?.invoiceRaisedDate) {
        tempErr.invoiceDt = true;
      }
      if (!invoiceData?.invoiceAmount) {
        tempErr.moneyReceivedDt = true;
      }
      if (!invoiceData?.invoiceNumber) {
        tempErr.invoiceNo = true;
      }
      if(!invoiceData?.invoiceMonth && !invoiceData?.invoiceYear){
        tempErr.extraMonth = true 
      }
      setError({ ...error, ...tempErr });
      return;
    }
    if (invoiceData?.invoiceAmount) {
      invoiceData.invoiceAmount = parseFloat(invoiceData?.invoiceAmount);
    }
    if (invoiceData?.moneyReceived) {
      invoiceData.moneyReceived = parseFloat(invoiceData?.moneyReceived);
    }
    if (add) {
      invoiceData.invoiceBy = employeeId;
      invoiceData.invoiceRaisedDate = invoiceData.invoiceRaisedDate
        ? handleInvoiceDateFormat(invoiceData.invoiceRaisedDate)
        : invoiceData.invoiceRaisedDate;
      invoiceData.moneyReceivedDate = invoiceData?.moneyReceivedDate
        ? handleInvoiceDateFormat(invoiceData?.moneyReceivedDate)
        : invoiceData?.moneyReceivedDate;

      addEditInvoiceMutation.mutate({ ...invoiceData });
    } else if (edit && invoiceId) {
      invoiceData.invoiceRaisedDate = invoiceData.invoiceRaisedDate
        ? handleInvoiceDateFormat(invoiceData.invoiceRaisedDate)
        : invoiceData.invoiceRaisedDate;
      invoiceData.moneyReceivedDate = invoiceData?.moneyReceivedDate
        ? handleInvoiceDateFormat(invoiceData?.moneyReceivedDate)
        : invoiceData?.moneyReceivedDate;
      invoiceData.invoiceUpdatedBy = employeeId;
      addEditInvoiceMutation.mutate({ ...invoiceData });
    }
  };

  useEffect(() => {
    if (edit) {
      setInvoiceData({
        ...preFillData,
        invoiceUpdatedBy: employeeId,
        projectId: id,
      });
    } else if (add) {
      setInvoiceData({
        invoiceAmount: null,
        invoiceBy: null,
        invoiceId: null,
        invoiceNumber: "",
        invoiceRaisedDate: undefined,
        invoiceUpdatedBy: null,
        moneyReceived: null,
        moneyReceivedDate: undefined,
        projectId: id,
      });
    }
  }, []);

  const mapMonth = {
    0: 1,
    1: 2,
    2: 3,
    3: 4,
    4: 5,
    5: 6,
    6: 7,
    7: 8,
    8: 9,
    9: 10,
    10: 11,
    11: 12
  }

  return (
    <div className={style.main}>
      <div className={style.header}>
        <div className={style.heading}>
          <span
            className={style.breadCrumb}
            onClick={handleRedirectProjectsList}
          >
            {location.pathname.includes("fix") ? "Fix Width Projects" : "T&M Projects"}
          </span>{" "}
          <span className={style.arrowbreadCrum}>&gt;</span>{" "}
          <span
            className={style.breadCrumb}
            onClick={handleRedirectProjectCosting}
          >
            {projectName}
          </span>{" "}
          <span className={style.arrowbreadCrum}>&gt;</span>{" "}
          <span
            className={style.breadCrumb}
            onClick={handleRedirectInvoiceList}
          >
            Invoices & Money Received
          </span>{" "}
          <span className={style.arrowbreadCrum}>&gt;</span>
          <span className={`${style.breadCrumb2}`}>
            {edit ? " Edit Invoice" : " Add Invoice"}
          </span>
        </div>
      </div>
      <div className={style.inputRow}>
        <div className={style.grid_item}>
          <div className={style.rowHead}>Invoice Date</div>
          <div className={style.csd}>
            <DatePicker
              dateFormat="MMM dd yy"
              startDate={null}
              value={
                invoiceData?.invoiceRaisedDate
                  ? Moment(invoiceData?.invoiceRaisedDate).format("DD MMM YY")
                  : undefined
              }
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="DD MMM YY"
              onChange={(date) => {
                console.log(date);
                setInvoiceData({ ...invoiceData, invoiceRaisedDate: date });
                setError({ ...error, invoiceDt: false });
              }}
              defaultValue={null}
              customInput={<CustomInputClg />}
              style={{ backgroundColor: "#F5F6FA" }}
            />
          </div>
          <div className={style.errorText}>
            {error?.invoiceDt && "Please Select Invoice Date"}
          </div>
        </div>
        <div className={style.grid_item}>
          <div className={style.rowHead}>Invoice No.</div>
          <div>
            <input
              className={style.rowVal}
              type="text"
              value={invoiceData?.invoiceNumber}
              onChange={(e) => {
                setInvoiceData({
                  ...invoiceData,
                  invoiceNumber: e.target.value,
                });
                setError({ ...error, invoiceNo: false });
              }}
            />
          </div>
          <div className={style.errorText}>
            {error?.invoiceNo && <>Please Enter Invoice No.</>}
          </div>
        </div>
        <div className={style.grid_item}>
          <div className={style.rowHead}>Invoice Amount</div>
          <div>
            <input
              className={style.rowVal}
              type="number"
              value={invoiceData?.invoiceAmount}
              onChange={(e) => {
                setInvoiceData({
                  ...invoiceData,
                  invoiceAmount: e.target.value,
                });
                setError({ ...error, moneyReceivedDt: false });
              }}
            />
            <div></div>
          </div>
          <div className={style.errorText}>
            {error?.moneyReceivedDt && <>Please Enter Invoice Amount</>}
          </div>
        </div>
        <div className={style.grid_item}>
          <div className={style.rowHead}>Money Received Amount</div>
          <div>
            <input
              className={style.rowVal}
              type="number"
              value={invoiceData?.moneyReceived}
              onChange={(e) =>
                setInvoiceData({
                  ...invoiceData,
                  moneyReceived: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className={style.grid_item}>
          <div className={style.rowHead}>Money Received Date</div>
          <div className={style.csd}>
            <DatePicker
              dateFormat="dd MMM yy"
              startDate={null}
              value={
                invoiceData?.moneyReceivedDate
                  ? Moment(invoiceData?.moneyReceivedDate).format("DD MMM YY")
                  : undefined
              }
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="DD MMM YY"
              onChange={(date) => {
                setInvoiceData({ ...invoiceData, moneyReceivedDate: date });
              }}
              defaultValue={null}
              customInput={<CustomInputClg />}
              style={{ backgroundColor: "#F5F6FA" }}
            />
          </div>
        </div>
        <div className={style.grid_item} style={{marginTop: "1.25rem"}}>
          <div className={style.rowHead}>Month</div>
          <div className={style.csd}>
            {/* <DatePicker
              dateFormat="MM YY"
              startDate={new Date("15.02.2025")}
              value={
                invoiceData?.invoiceRaisedDate
                  ? Moment(invoiceData?.invoiceRaisedDate).format("DD MMM YY")
                  : undefined
              }
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              showDayDropdown={false}
              dropdownMode="select"
              placeholderText="DD MMM YY"
              onChange={(date) => {
                setInvoiceData({ ...invoiceData, invoiceRaisedDate: date });
                setError({ ...error, invoiceDt: false });
              }}
              defaultValue={null}
              customInput={<CustomInputClg />}
              style={{ backgroundColor: "#F5F6FA" }}
            /> */}
            <DatePicker
              dateFormat="MMM YY"
              placeholderText="MM YY"            
              value={
                (invoiceData?.invoiceMonth && invoiceData?.invoiceYear) 
                  ?
                Moment(`${invoiceData?.invoiceYear}-${invoiceData?.invoiceMonth}`, 'YYYY-MM').format("MMM YY")
                  : 
                undefined
              }
              onChange={(date) => {
                console.log(date.getMonth());
                
                setInvoiceData({...invoiceData, invoiceMonth: mapMonth[date?.getMonth()], invoiceYear: date?.getFullYear() })
                setError({ ...error, extraMonth: false });
              }}
              showMonthYearPicker
              customInput={<CustomInputClg />}
              style={{ backgroundColor: "#F5F6FA" }}
            />
          </div>
          <div className={style.errorText}>
            {error?.extraMonth && "Please Select Month"}
          </div>
        </div>
      </div>
      <div className={style.btnRow}>
        <div>
          <button
            className={style.btnCancel}
            onClick={handleRedirectInvoiceList}
          >
            Cancel
          </button>
          <button className={style.btnSubmit} onClick={handleAddEditInvoice}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

const CustomInputClg = React.forwardRef((props, ref) => {
  return (
    <div onClick={props.onClick}>
      <label ref={ref} style={{ padding: "8px 8px 8px 5px", fontWeight: 600 }}>
        {props.value || props.placeholder}
      </label>
      <img
        type="image/svg+xml"
        width="20px"
        style={{ position: "absolute", right: "3%", top: "19%", zIndex: "0" }}
        src={calendarIcon}
        onClick={props.onClick}
        alt=""
      />
    </div>
  );
});
