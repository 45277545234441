import React, { useContext, useEffect, useState } from "react";
import style from "./tmOutput.module.css";
import { useQuery } from "react-query";
import {
  getAddCountsForDashboard,
  getfaSkillDashboard,
} from "../../../../Utils/axios/umsApi";
import { useHistory } from "react-router-dom";
import { path } from "../../../../routes/PathNames";
import { DataContext } from "../../../Projects_and_Resource_Master/CommonComponent/useContext/DataContext";
import * as Img from "../../../../assets/index";
import Sidebar from "../sidebar/sidebar";
import TmProjects from "./tmProjectsLists";
const TmOutput = () => {
  return (
    <div className={style.main_client_body}>
      <Sidebar />
      <TmProjects />
    </div>
  );
};
export default TmOutput;
