import { useEffect, useRef, useState } from 'react';
import style from './SearchTaskComponent.module.css';
import { AiOutlineClose } from 'react-icons/ai';
import searchIcon from '../../../../assets/Icon-search.png';
import ATSButton from '../../../atsDashboard/customTooltip/ATSButton';
import { useQuery, useQueryClient } from 'react-query';
import { searchTaskByEmployeeId, taskAssigneesAssignedByMe } from '../../taskTrackerAPIs/taskTrackerAPI';
import { RxCross2 } from 'react-icons/rx';
import Pagination from '../../commonComponent/Pagination';
import '../../Groups/groupList/customDropdown.css';
import { useHistory } from "react-router-dom";
import { formatNameGlobally, localStorageGetItem, localStorageSetItem } from '../../../../Utils/constants';
import Dropdown from 'react-bootstrap/Dropdown';
import { formatDateGlobally } from '../../../../Utils/constants';

const SearchTaskComponent = () => {
    const history = useHistory();
    const queryClient = useQueryClient();
    const plannedEndDateRefs = useRef([]);
    const [plannedEndDateWidth, setPlannedEndDateWidth] = useState(4);
    const [search, setSearch] = useState(localStorageGetItem("searchTaskComp") ?? '');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [active, setActive] = useState(localStorageGetItem('ttSTactiveName') ?? '');
    const [filter, setFilter] = useState({
      limit : 10,
      page : 1,
      closed : false,
    });
    const { data : assigneeNames } = useQuery(
        'taskAssigneesAssignedByMe',
        taskAssigneesAssignedByMe, {
            refetchOnWindowFocus : false,
        });
    const { data : taskList, isLoading, isFetching } = useQuery(
        ['searchTaskByEmployeeId',active, filter?.page, search, filter?.closed, filter?.limit],
        ()=>searchTaskByEmployeeId(active, search, filter?.limit, filter?.page, filter?.closed), {
            refetchOnWindowFocus : false,
            // enabled : active ? true : false,
            keepPreviousData : (active || search) ? true : false,
            placeholderData : []
        }
    );
    function handleSearch(e) {
        setSearch(e.target.value);
        setFilter(prev=>({  ...prev, page : 1 }));
    };
    function handleData(e) {
        setFilter(prev=>({  ...prev, page : 1 }));
        setActive(e?.id);
    };
    function handleColorByName(value) {
        if (value?.id === active) {
            return {
              marginLeft: "0.5rem",
              background: "#65a15e",
              border: "1px solid #65a15e",
              padding: "0.1rem 0.5rem",
              marginBottom: "0.2rem",
            };
          } else {
            return {
              marginLeft: "0.5rem",
              color: "black",
              background: "#fff",
              border: "1px solid black",
              padding: "0.1rem 0.5rem",
              marginBottom: "0.2rem",
            };
          }
    };
    function removeActive() {
        setActive('');
        localStorageSetItem('ttSTactiveName', null);
        queryClient.removeQueries(['searchTaskByEmployeeId',active, filter?.page, search, filter?.closed, filter?.limit]);
    };

    const handlePageChange = (page, status) => {
        if (status === "next") {
          setFilter(prev=>({
            ...prev,
            page : prev.page + 1
          }));
        } else if (status === "prev") {
            setFilter(prev=>({
                ...prev,
                page : prev.page - 1
              }));
        }
    };
    function handleCheckboxChange(e) {
        setFilter(prev=>({
            ...prev,
            page : 1,
            closed : e.target.checked
        })); 
    }
    function handleLimitChange(e) {
        setFilter(prev=>({
            ...prev,
            limit : e.target.value,
            page : 1
        }));
    };
    const handleView = (arg) => {
        history.push({
          pathname: "/taskTracker/SearchTask/ViewTask",
          state: {
            taskId: arg.taskId,
            task: arg,
            activePage: 1,
          },
        });
    };

    function handleScrollName(e) {
        setIsDropdownOpen(false);
    }

    useEffect(() => {
        if(active) localStorageSetItem('ttSTactiveName', active);
    },[active]);

    useEffect(() => {
      const widths = plannedEndDateRefs.current.map(td => td?.offsetWidth || 0);
      setPlannedEndDateWidth(Math.max(...widths));
    },[taskList]);

    useEffect(()=>{
      localStorageSetItem("searchTaskComp", search)
    },[search])

    return (
        <>
            <div className={style.header}>
                <div>
                    <span>Search Task</span>
                    <p>
                        <span>{(active || search) ? ((!isLoading) && (!isFetching) ? (taskList?.totalTask ?? 0) : taskList?.totalTask) : 0}</span>
                    </p>
                </div>

                <div style={{width: "40%"}}>
                    {!search ? (
                    <span className={style.cp_serchIcon}>
                        <img src={searchIcon} alt={"searchIcon"} />
                    </span>
                    ) : (
                    <span
                        className={style.cp_serchIcon}
                        onClick={() => {
                            setSearch('');
                            setFilter(prev=>({  ...prev, page : 1 }));
                        }}
                    >
                        <AiOutlineClose />
                    </span>
                    )}
                    <input
                      className={style.searchInput}
                      type="text"
                      placeholder="Search"
                      value={search}
                      onChange={handleSearch}
                    />
                    
                </div>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <input
                      style={{ visibility: 'visible', accentColor: "green", marginLeft: "10px", height: '20px', width: '20px' }}
                      type="checkbox"
                      checked={filter?.closed}
                      onChange={handleCheckboxChange}
                    />
                    <span style={{ marginLeft: '10px' }}>Show Closed Task</span>
                </div>

            </div>
            <div style={{overflow : 'visible'}}>
                {assigneeNames?.length > 0 && (
                    <div className={style.btnCantainer}>
                        <div className={style.cantainer} style={{display : 'flex'}}>
                            <div
                                className={style.innerBtn1}
                                onScroll={handleScrollName}
                            >
                                {assigneeNames?.length > 0
                                    ? assigneeNames?.map((e, index) => (
                                        index < 10 &&
                                    <ATSButton
                                        styles={handleColorByName(e)}
                                        onClick={() => handleData(e)}
                                        status={e?.id === active}
                                        key={index}
                                    >
                                        {e?.name}

                                    </ATSButton>
                                    ))
                                    : null}
                                {assigneeNames?.length > 10 && <Dropdown
                                  show={isDropdownOpen}
                                  onToggle={(isOpen) => setIsDropdownOpen(isOpen)}
                                  style={{ marginLeft: "8px" }}
                                >
                                  <Dropdown.Toggle className="custom-dropdown-toggle3" style={{ backgroundColor : assigneeNames?.findIndex(item=>item.id===active) > 9 ? '#65a15e' : 'transparent' }}>
                                    <span style={{ color : assigneeNames?.findIndex(item=>item.id===active) > 9 ? 'white' : 'blue', fontWeight : '400' }}>+More</span>
                                  </Dropdown.Toggle>
                                  
                                  <Dropdown.Menu className="custom-dropdown-menu3" show={false}>
                                  {assigneeNames?.length > 10
                                    ? assigneeNames?.map((e, index) => (
                                        index >= 10 &&
                                    <Dropdown.Item
                                      className="custom-dropdown-items custom-dropdown-menu-items-border"
                                      style={{ backgroundColor : e?.id === active ? '#65a15e' : 'white', color : e?.id === active ? 'white' : 'black', fontWeight : 400 }}
                                      onClick={() => handleData(e)}
                                    >{e?.name}</Dropdown.Item>
                                    ))
                                    : null}
                                  </Dropdown.Menu>
                                </Dropdown>}
  
                            </div>
                            {active && <span className={style.crossIcon} onClick={removeActive}><RxCross2 /></span>}
                        </div>
                    </div>
                )}
            </div>
            {(active || search) && <div className={style.IssueList}>
                <table style={{ width: "100%" }}>
                    <thead style={{ position : 'sticky', top : '0'}}>
                      <tr className={style.th} style={{display: "flex", alignItems: "center", justifyContent: "space-around"}}>
                        <th style={{ width: "3rem",paddingLeft: '.2rem' }}
                        >Task<br /> ID</th>
                        <th
                          style={{ minWidth: "56px" }}
                        //   onClick={handleSorting}
                        >
                          Creation <br/>
                          Date  
                        </th>
                        <th style={{width: "1.25rem"}}></th>
                        <th className={style.tableContent} style={{ width: "23rem" }}>Task Title</th>
                        <th style={{ width: "7rem" }}>Space</th>
                        <th className={style.tableContent} style={{ width: "6rem" }}>Assigned To</th>
                        <th className={style.tableContent} style={{ width: "6rem" }}>Assigned By</th>
                        <th className={style.tableContent} style={{ width: "5rem" }}>Status</th>

                        <th className={style.tableContent} style={{ width: "3.75rem" }}>Priority</th>
                        <th className={style.tableContent} style={{ minWidth: '60px', width: `${plannedEndDateWidth}px` }}>
                          Planned <br />
                          End Date
                        </th>
                      </tr>
                    </thead>
                    {(taskList?.length ===0 || taskList?.tasks?.length === 0) ? (
                      <tbody>
                        <tr style={{ textAlign: "center" }}>
                          <td colSpan="8">
                            <p className={style.noData}>No Data Available</p>
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody
                        style={{ height: "20px" }}
                      >
                        {taskList?.tasks?.map((task, index) => (
                          <tr key={task.taskId} className={style.td} style={{display: "flex", alignItems: "center", height : "40px"}}>
                            <td
                              style={{ width : "3rem",paddingLeft: '.2rem' }}
                            >
                              {task.taskId}
                            </td>
                            <td
                              className={style.tableContent}
                              style={{ minWidth: "56px" }}
                            >
                              {task?.created ? formatDateGlobally(task?.created) : '-'}
                            </td>
                            <td style={{width: "1.25rem"}}>{task?.recurringTask ? (<span
                              style={{
                                display: "inline-block",
                                fontSize: "10px",
                                color: "rgb(168, 50, 50)",
                                fontWeight: "800",
                                border: "1px solid rgb(168, 50, 50)",
                                borderRadius: "999px",
                                padding: "2px 5.5px",
                                height: "20px",
                                width: "20px",
                              }}
                            >
                              R
                            </span>) : null}</td>
                            <td
                              className={style.tabletaskTitle}
                              onClick={() => handleView(task)}
                              style={{
                                paddingRight : "4px",
                                width: "23rem",
                                fontSize: "15px",
                                fontWeight: "500",
                                textDecoration: "underline",
                                cursor: "pointer",
                                color: "rgb(101, 161, 94)",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              title={task?.title}
                            >
                              <div style={{width : "23rem", overflow: "hidden", fontWeight : "700", textOverflow : "ellipsis", whiteSpace : "nowrap"}}>{task.title}</div>
                            </td>
                            
                            <td style={{ width: "7rem", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", paddingRight : '8px' }} title={task.spaceName}>
                                {task.spaceName}
                            </td>
                            <td className={style.tableContent} style={{ width : "6rem", overflow : 'hidden', textOverflow : 'ellipsis', whiteSpace : 'nowrap', paddingRight : '8px' }} title={task.assignedToName}>{formatNameGlobally(task.assignedToName)}</td>
                            <td className={style.tableContent} style={{ width: "6rem", overflow : 'hidden', textOverflow : 'ellipsis', whiteSpace : 'nowrap', paddingRight : '8px' }} title={task.assignedByName}>
                              {formatNameGlobally(task.assignedByName)}
                            </td>
                            <td
                              className={
                                task.status === "Assigned"
                                  ? style.asigned_color
                                  : task.status === "Done"
                                    ? style.done_color
                                    : task.status === "Onhold"
                                      ? style.onhold_color
                                      : style.Closed_color
                              }
                              style={{ width: "5rem" }}
                            >
                              {task.status === "Closed"
                                ? "Closed"
                                : task.status === "Onhold"
                                  ? "On Hold"
                                  : task.status}
                            </td>
                            <td className={style.tableContent} style={{ width: "3.75rem" }}>
                              {task.priority}
                            </td>

                            <td ref={el => plannedEndDateRefs.current[index] = el} className={style.tableContent} style={{ minWidth: '60px', width : `${plannedEndDateWidth +1 }px`, textAlign: task?.planedEndDate ? "left" : "center", paddingRight : task?.planedEndDate ? "0" : "2rem"}}>
                              {task?.planedEndDate ? formatDateGlobally(task?.planedEndDate) : '-'}
                            </td>

                          </tr>
                        ))}
                      </tbody>
                    )}
                </table>
            </div>}
            {(active || search) && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{marginLeft: "1.3rem"}}>
                    <label htmlFor="limit">Items per page : &nbsp;</label>
                    <select className={style.selectPagination} id="limit" value={filter?.limit} onChange={handleLimitChange}>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                    </select>
                </div>

                {taskList?.totalNumberOfPagesAsPerGivenPageLimit > 1 && (
                    <div
                        className={style.paginationmobileContainer}
                        style={{ marginRight: "1.3rem", marginTop: "20px" }}
                    >
                        <Pagination
                            pagesCount={taskList?.totalNumberOfPagesAsPerGivenPageLimit}
                            pageSize={filter?.limit}
                            onPageChange={handlePageChange}
                            currentPage={filter?.page}
                        />
                    </div>
                )} 
            </div>}
        </>
    );
}

export default SearchTaskComponent