import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import classes from "../EditPartner/EditPartner.module.css";
import { BsFilePdf } from "react-icons/bs";
import { AiOutlineFileWord } from "react-icons/ai";
import ClearIcon from "@mui/icons-material/Clear";
import { useMutation, useQuery } from "react-query";
import { notifySuccess } from "../../../atsDashboard/utils/notify";
import {
  getViewPartner,
  createPartner,
  getPartnerTypeList,
  getSkills,
} from "../../../../Utils/axios/PortalManagement";
import { useState } from "react";
import BreadCrum from "../../../PartnerManagement/breadcrums/BreadCrum";
import { urlValidation } from "../../../atsDashboard/utils/validation";
import {
  getCityList,
  getWorkModeList,
  getStateList,
} from "../../../../Utils/axios/PortalManagement";
import { ToastifyError } from "../../../../App";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { InvoiceStyles4 } from "../../utils/style";
import dropdownIcon from "../../../../assets/downIcon.png";
import axios from "axios";
import calender from "../../../../assets/calblue.png";
import cancel from "../../../../assets/cancell.png";
import { employeeId } from "../../../../Utils/constants";
import { Modal as ResponsiveModal } from "react-responsive-modal";
import OptionOrdering from "../../CommonComponent/optionModal/OptionOrdering";
const EditPartner = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const { data: PartnerDataById } = useQuery(
    ["getViewPartner1"],
    () => getViewPartner(prevData?.partnerId),
    {}
  );

  const [validate, setValidate] = React.useState(false);
  const [OptionModal, setOptionModal] = useState(false);
  const baseUrl = process.env.REACT_APP_PARTNER_MANAGEMENT;
  const [formData, setFormData] = useState({
    partnerName: PartnerDataById?.partnerName,
    gstin: PartnerDataById?.gstin,
    msafileUrl: PartnerDataById?.msafileUrl,
    ndafileUrl: PartnerDataById?.ndafileUrl,
    addressLine1: PartnerDataById?.addressLine1,
    addressLine2: PartnerDataById?.addressLine2,
    country: PartnerDataById?.country,
    cityId: PartnerDataById?.cityId,
    workModeId: PartnerDataById?.workModeId,
    partnerTypeId: PartnerDataById?.partnerTypeId,
    stateId: PartnerDataById?.stateId,
    pinCode: PartnerDataById?.pinCode,
    linkedinLink: PartnerDataById?.linkedinLink,
    companyLink: PartnerDataById?.companyLink,
    primaryContactName: PartnerDataById?.primaryContactName,
    primaryContectEmail: PartnerDataById?.primaryContectEmail,
    primaryMobileNumber: PartnerDataById?.primaryMobileNumber,
    seconderyContactName: PartnerDataById?.seconderyContactName,
    seconderyContectEmail: PartnerDataById?.seconderyContectEmail,
    seconderyMobileNumber: PartnerDataById?.seconderyMobileNumber,
    id: PartnerDataById?.id,
    empId: Number(employeeId),
    paymentDueDays: PartnerDataById?.pamentDueDays,
    companySize: PartnerDataById?.companySize,
    paymentDueDaysAsPrPartnerRequest:
      PartnerDataById?.paymentDueDaysAsPrPartnerRequest,
    specializationId: PartnerDataById?.specializationId || [],
  });

  const mutation = useMutation(createPartner, {
    onSuccess: (res) => {
      notifySuccess("Partner Updated Successfully")
      setNextScreen("ViewPartner");
      setPrevData((prevData) => ({
        ...prevData,
        page1: "PartnerList",
        page2: "ViewPartner",
        page3: null,
        page4: null,
        active: "ViewPartner",
      }));
    },
  });

  const handleInputClick = (e) => {
    e.target.addEventListener("mousewheel", (ev) => {
      e.target.blur();
    });
  };

  const handleKeyDown = (e) => {
    // Define the list of characters to prevent
    const forbiddenKeys = ["+", "-", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "_", "=", "{", "}", "[", "]", "|", "\\", ":", ";", "'", "\"", "<", ">", ",", ".", "?", "/", "`", "~"];

    if (forbiddenKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const HandlerEditPartner = () => {
    console.log("HandlerEditPartner");
    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
    if (
      formData?.partnerName?.trim() !== "" &&
      formData?.addressLine1?.trim() !== "" &&
      formData?.addressLine2?.trim() !== "" &&
      formData?.msafileUrl !== "" &&
      formData?.ndafileUrl !== "" &&
      formData?.country?.trim() !== "" &&
      formData?.primaryContactName?.trim() !== "" &&
      formData?.pinCode?.trim() !== "" &&
      formData?.workModeId !== "" &&
      formData?.linkedinLink?.trim() &&
      formData?.companyLink?.trim() &&
      formData?.companySize?.trim() !== "" &&
      formData?.primaryContectEmail !== "" &&
      linkendInUrl(formData?.linkedinLink) &&
      linkendInUrl(formData?.companyLink) &&
      formData.primaryMobileNumber.length >= 10 &&
      isValidEmail(formData?.primaryContectEmail) &&
      formData?.primaryMobileNumber !== "" &&
      formData?.specializationId?.length > 0 
    ) {
      setValidate(false);
      let data = {
        id: formData?.id,
        empId: Number(employeeId),
        partnerName: formData?.partnerName?.trim(),
        gstin: formData?.gstin?.trim(),
        companySize: formData?.companySize?.trim(),
        addressLine1: formData?.addressLine1?.trim(),
        addressLine2: formData?.addressLine2?.trim(),
        country: formData?.country?.trim(),
        ndafileUrl: formData?.ndafileUrl,
        msafileUrl: formData?.msafileUrl,
        workModeId: formData?.workModeId,
        partnerTypeId: formData?.partnerTypeId,
        linkedinLink: formData?.linkedinLink?.trim(),
        companyLink: formData?.companyLink?.trim(),
        cityId: formData?.cityId,
        stateId: formData?.stateId,
        pinCode: formData?.pinCode?.trim(),
        primaryContactName: formData?.primaryContactName?.trim(),
        primaryContectEmail: formData?.primaryContectEmail?.trim(),
        primaryMobileNumber: formData?.primaryMobileNumber?.trim(),
        seconderyContectEmail: formData?.seconderyContectEmail?.trim() || null,
        secondaryContactName: formData?.seconderyContactName?.trim() || null,
        seconderyMobileNumber: formData?.seconderyMobileNumber?.trim() || null,
        specializationId: formData?.specializationId.map((i) => i.value),
        paymentDueDays: formData?.paymentDueDays,
        paymentDueDaysAsPrPartnerRequest: formData?.paymentDueDaysAsPrPartnerRequest,
      }
      mutation.mutate(data);
    } else {
      setValidate(true);
    }
  };

  useEffect(() => {
    setFormData((prevValue) => ({
      ...prevValue,
      partnerName: PartnerDataById?.partnerName,
      gstin: PartnerDataById?.gstin,
      addressLine1: PartnerDataById?.addressLine1,
      addressLine2: PartnerDataById?.addressLine2,
      country: PartnerDataById?.country,
      msafileUrl: PartnerDataById?.msafileUrl,
      ndafileUrl: PartnerDataById?.ndafileUrl,
      workModeId: PartnerDataById?.workModeId,
      cityId: PartnerDataById?.cityId,
      stateId: PartnerDataById?.stateId,
      pinCode: PartnerDataById?.pinCode,
      linkedinLink: PartnerDataById?.linkedinLink,
      primaryContactName: PartnerDataById?.primaryContactName,
      primaryContectEmail: PartnerDataById?.primaryContectEmail,
      primaryMobileNumber: PartnerDataById?.primaryMobileNumber,
      seconderyContactName: PartnerDataById?.seconderyContactName,
      seconderyContectEmail: PartnerDataById?.seconderyContectEmail,
      seconderyMobileNumber: PartnerDataById?.seconderyMobileNumber,
      paymentDueDays: PartnerDataById?.pamentDueDays,
      id: PartnerDataById?.id,
      empId: Number(employeeId),
      companySize: PartnerDataById?.companySize,
      paymentDueDaysAsPrPartnerRequest: PartnerDataById?.paymentDueDaysAsPrPartnerRequest,
      specializationId: PartnerDataById?.specialization?.map((item) => ({
        value: item.specializationId,
        label: item.specialization,
      }))
    }));
  }, [PartnerDataById]);

  const { data: GetCityTypeData } = useQuery(["getCityList"], getCityList);
  const GetCityDataOption = GetCityTypeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const { data: getSkillData } = useQuery(["getSkills"], getSkills);

  const getSkillOption =
    getSkillData?.length > 0
      ? getSkillData?.map((i) => ({
        value: i.skillId,
        label: i.coreSkillName,
      }))
      : [];

  const { data: GetStateTypeData } = useQuery(["getStateList"], getStateList);

  const GetStateDataOption = GetStateTypeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));
  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
          alt=""
        />
      </span>
    );
  };

  async function handleFileUpload(file) {
    setFormData({ ...formData, ndafileUrl: file.name });
    const formDataa = new FormData();
    formDataa.append("file", file);

    try {
      const response = await axios.post(baseUrl + "/upload-file", formDataa);
      if (response.status === 200) {
        setFormData({ ...formData, ndafileUrl: response.data.responseData });
      } else {
        // handle error
      }
    } catch (error) {
      // handle error
    }
  }
  async function handleFileUpload1(file) {
    setFormData({ ...formData, msafileUrl: file.name });
    const formDataa = new FormData();
    formDataa.append("file", file);

    try {
      const response = await axios.post(baseUrl + "/upload-file", formDataa);
      if (response.status === 200) {
        setFormData({ ...formData, msafileUrl: response.data.responseData });
      } else {
        // handle error
      }
    } catch (error) {
      // handle error
    }
  }
  async function handleFileDeletion() {
    // setFormData({...formData, ndafileUrl:""});
    try {
      const response = await axios.put(
        baseUrl + `/delete-file-url?url=${formData.ndafileUrl}`
      );
      if (response.status === 200) {
        setFormData({ ...formData, ndafileUrl: "" });
      } else {
        // handle error
      }
    } catch (error) {
      // handle error
    }
  }
  async function handleFileDeletion1() {
    // setFormData({...formData, msafileUrl:""});
    try {
      const response = await axios.put(
        baseUrl + `/delete-file-url?url=${formData.msafileUrl}`
      );
      if (response.status === 200) {
        setFormData({ ...formData, msafileUrl: "" });
      } else {
        // handle error
      }
    } catch (error) {
      // handle error
    }
  }
  const checkfileType = (value) => {
    switch (value) {
      case "pdf":
        return <BsFilePdf />;
      case "docx":
        return <AiOutlineFileWord />;
    }
  };
  const CustomInputCross = React.forwardRef((props, ref) => {
    return (
      <div>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          alt="cancel"
          width="10px"
          height="10px"
          style={{
            position: "absolute",
            right: "5%",
            top: "16%",
            cursor: "pointer",
          }}
          onClick={() => setFormData({ ...formData, [props?.properties]: "" })}
        />
      </div>
    );
  });

  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={calender}
          alt="calender"
          style={{
            position: "absolute",
            right: "5%",
            top: "13%",
            cursor: "pointer",
          }}
        />
      </div>
    );
  });
  const linkendInUrl = (value) => {
    if (!value) {
      return true;
    } else if (urlValidation(value)) {
      return true;
    } else {
      return false;
    }
  };
  let GetWorkMode;
  const { data: GetWorkModeData } = useQuery(
    ["getWorkModeList"],
    getWorkModeList
  );
  if (GetWorkModeData?.message !== "Access Denied") {
    GetWorkModeData?.sort((a, b) =>
      a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
    );

    GetWorkMode = GetWorkModeData?.map((e) => ({
      value: e.id,
      label: e.name,
    }));
  }

  const { data: getPartnerTypeData } = useQuery(
    ["getPartnerTypeList"],
    getPartnerTypeList
  );

  const getPartnerType = getPartnerTypeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  return (
    <>
      <div style={{ marginLeft: "10px" }}>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
        ></BreadCrum>
      </div>
      <Container>
        <Row>
          <Col>
            <h6 style={{ marginLeft: "25px" }}>Edit Partner</h6>
          </Col>
        </Row>
        <InvoiceContainer>
          <Row>
            <Col md={4}>
              <p className={classes.label}>
                Partner Name <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                value={formData?.partnerName}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    partnerName: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.partnerName?.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Partner Name
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label}>GSTIN</p>
              <input
                type="text"
                value={formData?.gstin}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    gstin: e.target.value,
                  });
                }}

                onKeyDown={handleKeyDown}
                onClick={handleInputClick}
              />
              {/* <p
                className={
                  validate && !formData?.gstin?.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter GSTIN
              </p> */}
            </Col>
            <Col md={4}>
              <p className={classes.label}>
                Company Size <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                value={formData?.companySize}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    companySize: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.companySize?.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Company Size
              </p>
            </Col>

            <Col md={4}>
              <p className={classes.labelStatus} style={{ marginTop: "10px" }}>
                Address 1 <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                value={formData?.addressLine1}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    addressLine1: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.addressLine1?.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Address 1
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Address 2 <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                value={formData?.addressLine2}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    addressLine2: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.addressLine2?.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Address 2
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Payment Due Days (As per Agreement)
              </p>

              <input
                type="text"
                className={classes.smallInput}
                value={formData?.paymentDueDays}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    paymentDueDays: Number(e.target.value),
                  });
                }}
              />
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Payment Due Days (As per Partner Requested)
              </p>

              <input
                type="text"
                className={classes.smallInput}
                value={formData?.paymentDueDaysAsPrPartnerRequest}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    paymentDueDaysAsPrPartnerRequest: Number(e.target.value),
                  });
                }}
              />
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                City <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                options={GetCityDataOption}
                styles={InvoiceStyles4}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                value={GetCityDataOption?.find(
                  (e) => e.value === formData?.cityId
                )}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    cityId: e?.value,
                  });
                }}
              />
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                State <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                options={GetStateDataOption}
                styles={InvoiceStyles4}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                value={GetStateDataOption?.find(
                  (e) => e.value === formData?.stateId
                )}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    stateId: e?.value,
                  });
                }}
              />
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Country <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                value={formData?.country}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    country: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.country?.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Country
              </p>
            </Col>

            <Col md={4}>
              <p className={classes.label1}>
                Pin Code <span style={{ color: "red" }}>*</span>
              </p>
              <input
                value={formData?.pinCode}
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    pinCode: e.target.value,
                  });
                }}
                onKeyDown={handleKeyDown}
                onClick={handleInputClick}
              />
              <p
                className={
                  validate && !formData?.pinCode?.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Pin Code
              </p>
            </Col>
            <Col md={4} style={{ marginTop: "9px" }}>
              <p className={classes.label}>
                LinkedIn Url <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                value={formData?.linkedinLink}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    linkedinLink: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate &&
                    (!formData?.linkedinLink?.trim() ||
                      !linkendInUrl(formData?.linkedinLink))
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter LinkedIn Url
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.cu_label1}>
                Upload MSA <span style={{ color: "red" }}>*</span>{" "}
              </p>
              <div className={classes.chooseFile}>
                <div>
                  {formData?.msafileUrl ? (
                    <div
                      className={classes.rmpdf}
                      style={{ paddingTop: "3px" }}
                    >
                      {
                        <>
                          {checkfileType(formData?.msafile?.split(".")[1])}
                          &nbsp;
                          <span>
                            {formData?.msafileUrl &&
                              formData?.msafileUrl.length > 25
                              ? `${formData?.msafileUrl
                                ?.split("@")[1]
                                ?.substring(0, 25)}...`
                              : formData?.msafileUrl}
                          </span>
                        </>
                      }
                      <ClearIcon
                        onClick={handleFileDeletion1}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  ) : (
                    <input
                      id="file-upload"
                      accept="image/*, .pdf, .doc, .docx"
                      type="file"
                      className={classes.upload_resume}
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          const fileSize = file.size / 1024 / 1024; // File size in MB
                          if (fileSize <= 10) {
                            handleFileUpload1(file);
                          } else {
                            ToastifyError(
                              "File size exceeds the limit of 10MB."
                            );
                            e.target.value = null; // Reset the file input value
                            return;
                          }
                        }
                      }}
                    />
                  )}
                </div>
              </div>
              <p
                className={
                  validate && !formData?.msafileUrl
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Upload MSA
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.cu_label1}>
                Upload NDA <span style={{ color: "red" }}>*</span>
              </p>
              <div className={classes.chooseFile}>
                <div>
                  {formData?.ndafileUrl ? (
                    <div
                      className={classes.rmpdf}
                      style={{ paddingTop: "3px" }}
                    >
                      {
                        <>
                          {checkfileType(formData?.ndafileUrl?.split(".")[1])}
                          &nbsp;
                          <span>
                            {formData?.ndafileUrl &&
                              formData?.ndafileUrl.length > 25
                              ? `${formData?.ndafileUrl
                                ?.split("@")[1]
                                ?.substring(0, 25)}...`
                              : formData?.ndafileUrl}
                          </span>
                        </>
                      }
                      <ClearIcon
                        onClick={handleFileDeletion}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  ) : (
                    <input
                      id="file-upload"
                      accept="image/*, .pdf, .doc, .docx"
                      type="file"
                      className={classes.upload_resume}
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          const fileSize = file.size / 1024 / 1024; // File size in MB
                          if (fileSize <= 10) {
                            handleFileUpload(file);
                          } else {
                            ToastifyError(
                              "File size exceeds the limit of 10MB."
                            );
                            e.target.value = null; // Reset the file input value
                            return;
                          }
                        }
                      }}
                    />
                  )}
                </div>
              </div>
              <p
                className={
                  validate && !formData?.ndafileUrl
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Upload NDA
              </p>
            </Col>
            <Col md={4} style={{ marginTop: "-11px" }}>
              <p className={classes.label1}>
                Work Mode <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                options={GetWorkMode}
                styles={InvoiceStyles4}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                value={GetWorkMode?.find(
                  (e) => e.value === formData?.workModeId
                )}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    workModeId: e?.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.workModeId
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Select Work Mode
              </p>
            </Col>
          </Row>

          <Row>
            <Col md={4} style={{ marginTop: "9px" }}>
              <p className={classes.label}>
                Company Url <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                value={formData?.companyLink}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    companyLink: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate &&
                    (!formData?.companyLink?.trim() ||
                      !linkendInUrl(formData?.companyLink))
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Company Url
              </p>
            </Col>
            <Col md={4} >
              <p className={classes.label1}>
                Partner Type <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                options={getPartnerType}
                styles={InvoiceStyles4}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                value={getPartnerType?.find(
                  (e) => e.value === formData?.partnerTypeId
                )}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    partnerTypeId: e?.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.partnerTypeId
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Select Work Mode
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Specialization <span style={{ color: "red" }}>*</span>
              </p>
              {
                <div className={classes.add} onClick={() => setOptionModal(true)}>
                  <div className={classes.plus}>+</div>
                </div>
              }
              {formData?.specializationId?.length > 0 ? (
                <p
                  className={classes.mandatoryP}
                // style={{ color: "rgb(96, 228, 96)" }}
                >
                  {formData?.specializationId?.length > 0 ?
                    formData?.specializationId
                      ?.map((item) => item.label)
                      .join(", ") : null}
                </p>
              ) : (
                <p
                  className={classes.mandatoryP}
                  onClick={() => setOptionModal(true)}
                >
                  Please Select Specialization.
                </p>
              )}
              <ResponsiveModal
                open={OptionModal}
                center
                onClose={() => setOptionModal(false)}
                showCloseIcon={false}
                classNames={{
                  modal: "reactmodal",
                }}
              >
                <OptionOrdering
                  getMandatorySkillOption={getSkillOption}
                  formData={formData}
                  setFormData={setFormData}
                  setOptionModal={setOptionModal}
                  coreSkillId={formData?.coreSkill_Id}
                />
              </ResponsiveModal>
              <p
                className={
                  validate && !formData?.specializationId?.length > 0
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Select Specialization.
              </p>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <h6 className={classes.primaryContact}>Primary Contact</h6>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Name <span style={{ color: "red" }}>*</span>
              </p>
              <input
                value={formData?.primaryContactName}
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    primaryContactName: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.primaryContactName?.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Name
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Email ID <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                value={formData?.primaryContectEmail}
                className={classes.smallInput}
                onChange={(e) => {
                  const enteredEmail = e.target.value;
                  const isValidEmail = enteredEmail.includes("@"); // Check for "@" symbol
                  setFormData({
                    ...formData,
                    primaryContectEmail: enteredEmail,
                    isValidEmail: isValidEmail,
                  });
                }}
              />
              <p
                className={
                  validate &&
                    (!formData?.primaryContectEmail ||
                      !isValidEmail(formData?.primaryContectEmail))
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                {validate &&
                  (!formData?.primaryContectEmail ||
                    !isValidEmail(formData?.primaryContectEmail))
                  ? "Please Enter a Valid Email"
                  : "Please Enter Name"}
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Mobile Number <span style={{ color: "red" }}>*</span>
              </p>
              <input
                value={formData?.primaryMobileNumber}
                type="number"
                className={classes.smallInput}
                onChange={(e) => {
                  let inputVal = e.target.value;
                  if (inputVal.length > 10) {
                    inputVal = inputVal.slice(0, 10);
                  }
                  setFormData({
                    ...formData,
                    primaryMobileNumber: inputVal,
                  });
                }}
                maxLength={10}
                onWheel={(e) => e.target.blur()}
                onKeyDown={(e) =>
                  (e.keyCode === 38 ||
                    e.keyCode === 40 ||
                    e.key === "e" ||
                    e.key === "-") &&
                  e.preventDefault()
                }
                onKeyPress={(e) =>
                  ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                    e.which > 57 ||
                    e.key === "e" ||
                    e.key === "-") &&
                  e.prevent
                }
              />
              <p
                className={
                  validate &&
                    (!formData?.primaryMobileNumber ||
                      formData.primaryMobileNumber.length < 10)
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                {formData?.primaryMobileNumber &&
                  formData.primaryMobileNumber.length < 10
                  ? "Mobile number should have at least 10 digits"
                  : "Please Enter Mobile Number"}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h6 className={classes.SecondaryContact}>Secondary Contact</h6>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>Name</p>
              <input
                type="text"
                value={formData?.seconderyContactName}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    seconderyContactName: e.target.value,
                  });
                }}
              />
            </Col>
            <Col md={4}>
              <p className={classes.label1}>Email ID</p>
              <input
                value={formData?.seconderyContectEmail}
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    seconderyContectEmail: e.target.value,
                  });
                }}
              />
            </Col>
            <Col md={4}>
              <p className={classes.label1}>Mobile Number</p>
              <input
                type="text"
                value={formData?.seconderyMobileNumber}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    seconderyMobileNumber: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
        </InvoiceContainer>
      </Container>
      <button className={classes.buttonAdd} onClick={HandlerEditPartner}>
        Update
      </button>
    </>
  );
};
export default EditPartner;
