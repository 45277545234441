export const path = {
  // ums
  ums_dashboard: "/ums-dashboard",
  ums_userList: "/UsersList",
  ums_createUser: "/CreateUser",
  ums_createconfirm: "/CreateCanfirmation",
  ums_userDescription: "/UserDescription",
  ums_userDescription2: "/UserDescription/:id",
  ums_editUser: "/EditUser",
  ums_newEmployee: "/new-employee",
  ums_exitEmployee: "/exit-employee",
  ums_exitedEmployee: "/exited-employees",
  ums_allExitedEmployee: "/all-exited-employee",
  ums_confirmationEmployee: "/confirmation-employee",
  ums_report: "/report",
  ums_incentive_report: "/incentive-report",
  ums_newJoinee: "/new-joinees",
  ums_bgvReport: "/bgv-report",
  ums_salaryMaster: "/salary-master",
  ums_companyHierarchy : "/hierarchy",

  // client contract
  cc_list: "/expiring-contracts",
  cc_contract_ims: "/client-contracts",
  cc_missing_inprogress:"/missing-inprogress-contracts",
  cc_listById: "/:name/contract-list/:id",
  cc_viewById: "/:name/contract-view/:id",
  cc_listMissingInprogressById: "/:name/contract-missing-inprogress-view/:id",
  cc_edit: "/:name/edit-contract",
  cc_create: "/:name/create-contract",

  // portal management
  portalManagement: "/PortalManagement",
  projectManagement: "/PortalManagement/ProjectManagement",
  //price revision application
  price_listing: "/price_listing",
  resource_details: "/resource_details",
  revision_dues: "/revision_dues",
  
  // APPRAISAL SYSTEM
  ASDashboard:"/ASDashboard",
  cycleEmployeeList:`/ASDashboard/:cycleName/:cycleId/CycleEmployeeList`,
  ASEmployeeList:"/ASEmployeeList",
  EmployeeDetails:"/ASDashboard/:cycleName/:cycleId/CycleEmployeeList/EmployeeDetails/:empId",
  EmployeeInformation:"/ASEmployeeList/EmployeeInformation/:empId",
  
  // Exit Formalities
  ef_dashboard:"/ef_dashboard",
  ef_employeeExit:"/ef_employeeExit",
  ef_employeeDetails:`/ef_employeeExit/:empId/employeeDetails`,
  ef_employeeEdit:`/ef_employeeExit/:empId/employeeEdit`,

  //Project Costing And Margin
  pcm_dashboard:"/pcm_dashboard",  // Not Using

  pcm_dashboard_fixedBidProjects: "/pcm_dashboard_fixedBidProjects",  // New
  pcm_tmProjects: "/pcm_tmProjects",  // New

  pcm_editProject: "/editProjectCosting", // Not Using
  pcm_editProjectFixBid: "/pcm_editProjectFixBid",  // New
  pcm_editProjectTM: "/pcm_editProjectTM",  // New

  pcm_overheads: "/pcm_overheads",
  pcm_invoices: "/pcm_invoices",  // Not Using
  pcm_fixBid_invoices: "/pcm_dashboard_fixedBidProjects/pcm_invoices", // New
  pcm_tm_invoices: "/pcm_tmProjects/pcm_invoices",  // New

  pcm_editInvoice: "/pcmEditInvoice", // Not Using
  pcm_editInvoiceFixBid: "/pcm_dashboard_fixedBidProjects/pcm_invoices/pcmEditInvoiceFixBid", // New
  pcm_editInvoiceTM: "/pcm_tmProjects/pcm_invoices/pcm_EditInvoiceTM", // New

  pcm_addInvoice: "/pcmAddInvoice", //
  pcm_addInvoiceFixBid: "/pcm_dashboard_fixedBidProjects/pcm_invoices/pcmAddInvoiceFixBid", // New
  pcm_addInvoiceTM: "/pcm_tmProjects/pcm_invoices/pcmAddInvoiceTM", // New

  pcm_projectCostingInformation: "/pcmProjectCostingInformation",  // Not Using
  pcm_projectCostingInformationFixBid: "/pcmProjectCostingInformationFixBid",  // New 
  pcm_projectCostingInformationTM: "/pcmProjectCostingInformationTM",  // New

  pcm_projectCostBreakUp: "/pcmProjectCostBreakUp",  // Not Using
  pcm_projectCostBreakUpFixBid: "/pcmProjectCostBreakUpFixBid", // New
  pcm_projectCostBreakUpTM: "/pcm_projectCostBreakUpTM", // New
  pcm_resourceMargin: "/pcmResourceMargin",
};
