import { path } from "../../../../routes/PathNames";
import * as img from "../../../../assets/index";
import resource from "../../../../assets/hr-icon.png"
import { projectCostingAndMarginAccessCode } from "../../../../Utils/constants";

export const menuItems = [
  // This module is divided into 2 sections: 'Fixed Bid Projects' and 'T & M Projects'...
  // {
  //   path: path.pcm_dashboard,
  //   imgSrc: img.projects,
  //   imgSrcSelected: img.projectsSelected,
  //   heading: "Projects (Offshore)",
  //   validPaths: [
  //     path.pcm_dashboard,
  //     path.pcm_editProject,
  //     path.pcm_invoices,
  //     path.pcm_editInvoice,
  //     path.pcm_addInvoice,
  //     path.pcm_projectCostingInformation,
  //     path.pcm_projectCostBreakUp,
  //   ],
  //   accessCode: projectCostingAndMarginAccessCode?.allProjects_dashboard,
  // },
  {
    path: path.pcm_dashboard_fixedBidProjects,
    imgSrc: img.projects,
    imgSrcSelected: img.projectsSelected,
    heading: "Fixed Bid Projects",
    validPaths: [
      path.pcm_dashboard_fixedBidProjects,
      path.pcm_editProjectFixBid,
      path.pcm_fixBid_invoices,
      // path.pcm_editInvoice,
      path.pcm_editInvoiceFixBid,
      // path.pcm_addInvoice,
      path.pcm_addInvoiceFixBid,
      path.pcm_projectCostingInformationFixBid,
      path.pcm_projectCostBreakUpFixBid,
    ],
    // using the same access-code as 'allProjects_dashboard'
    accessCode: projectCostingAndMarginAccessCode?.allProjects_dashboard,
  },
  {
    path: path.pcm_tmProjects,
    imgSrc: img.projects,
    imgSrcSelected: img.projectsSelected,
    heading: "T & M Projects",
    validPaths: [
      path.pcm_tmProjects,
      path.pcm_editProjectTM,
      path.pcm_tm_invoices,
      // path.pcm_editInvoice,      
      path.pcm_editInvoiceTM,
      // path.pcm_addInvoice,
      path.pcm_addInvoiceTM,
      path.pcm_projectCostingInformationTM,
      path.pcm_projectCostBreakUpTM,
    ],
    // using the same access-code as 'allProjects_dashboard'
    accessCode: projectCostingAndMarginAccessCode?.allProjects_dashboard,
  },
  {
    path: path.pcm_overheads,
    imgSrc: img.overhead,
    imgSrcSelected: img.overheadSelected,
    heading: "Overheads",
    validPaths: [path.pcm_overheads],
    accessCode: projectCostingAndMarginAccessCode?.overheads_dashboard,
  },
  {
    path: path.pcm_resourceMargin,
    imgSrc: resource,
    imgSrcSelected: resource,
    heading: "Resource Margins",
    validPaths: [path.pcm_resourceMargin],
    accessCode: projectCostingAndMarginAccessCode?.resourceMargins,
  },
];
