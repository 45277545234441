import { useLocation, useHistory } from "react-router-dom";
import SideBar from "../../sidebar/sidebar";
// import style from "./allProjects.module.css";
import style from "../fixedBidProjects.module.css";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { path } from "../../../../../routes/PathNames";
import { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { addEditAdditionalProjectDetails } from "../../../../../Utils/axios/pcm_Api";
import { notifyError, notifySuccess } from "../../../../atsDashboard/utils/notify";
import { MdComment } from "react-icons/md";
import Modal from "react-responsive-modal";
import { addCommasPCM, addCommasWithDecimal } from "../../../../Appraisal_System/commonComponent/utils/AddCommas";
import { round } from "lodash";
import useValidationAccess from "../../../../custumHooks/useValidationAccess";
import { projectCostingAndMarginAccessCode } from "../../../../../Utils/constants";

export default function EditProjectFixBid() {
  const { checkValidateAccess } = useValidationAccess();
  return (
    <div style={{ display: "flex" }}>
      {checkValidateAccess(projectCostingAndMarginAccessCode?.addSOWValue) && (
        <>
          <SideBar />
          <Body />
        </>
      )}
    </div>
  );
}

function Body() {
  const location = useLocation();
  const history = useHistory();
  const dt = location?.state?.dt;
  const [error, setError] = useState(false);
  const projectName = location?.state?.projectName;
  const projectDetails = location?.state?.projectDetails;
  const [showAddNote, setShowAddNote] = useState(false);
  const [projectAdditionalData, setProjectAdditionalData] = useState({
    isSoftAllocated: false,
    otherCosts:
      projectDetails?.additionalCostOfAProject === null
        ? null
        : projectDetails?.additionalCostOfAProject,
    projectId: projectDetails?.projectId,
    projectOtherCostsInfoId: projectDetails?.projectOtherCostInfoId,
    projectSOWValue:
      projectDetails?.projectSOWValue === null
        ? null
        : projectDetails?.projectSOWValue,
    notesForOtherCosts:
      projectDetails?.notesForAdditionalAmount === null
        ? null
        : projectDetails?.notesForAdditionalAmount,
  });
  const editProjectAdditionalDetails = useMutation(
    addEditAdditionalProjectDetails,
    {
      retry: false,
      onSuccess: (res) => {
        console.log(res);
        if (res?.message) {
          notifySuccess(res?.responseData?.status);
          setTimeout(() => {
            handleRedirect();   
          }, 10);
        } else {
          notifyError(res?.responseData?.message || "Something went wrong!");
        }
      },
      onError: (err) => {
        notifyError(err);
      },
    }
  );
  console.log("projectDetails --", projectDetails);

  const handleSubmit = () => {
    if (!projectAdditionalData?.projectSOWValue) {
      setError(true);
      return;
    }
    editProjectAdditionalDetails.mutate({
      ...projectAdditionalData,
      otherCosts: parseFloat(projectAdditionalData?.otherCosts),
      projectSOWValue: parseFloat(projectAdditionalData?.projectSOWValue)
    });
  };

  function roundToZeroDecimalPlaces(number) {
    return addCommasPCM(number.toFixed(0));
  }

  const handleRedirect = () => {
    setTimeout(() => {
      history.push(path.pcm_dashboard_fixedBidProjects, {
        dt,
        pccurrentPage: location?.state?.pccurrentPage,
      });
    }, 0);
  };

  return (
    <div className={style.editPrBody}>
      {/* <AddAdditionalNoteModal  initialNote={projectDetails?.notesForAdditionalAmount} showAddNote={showAddNote} setShowAddNote={setShowAddNote} addAmtNote={projectAdditionalData?.notesForOtherCosts} setAddAmtNote={(note) => setProjectAdditionalData(prev => ({...prev, notesForOtherCosts: note}))} /> */}
      <div className={style.heading}>
        <span onClick={handleRedirect} className={style.breadCrumb}>
          Fixed Bid Projects
          {/* {projectName} */}
        </span>{" "}
        &gt; <span className={`${style.cmnt_icon}`}>{projectName}</span>
      </div>
      <div className={style.gridContainer}>
        <div>
          <div>
            <div className={style.rowHead}>Project Name</div>
            <div className={style.rowValue}>{projectDetails?.projectName}</div>
          </div>
          <div className={style.gridSubItem} style={{ marginTop: "52px" }}>
            <div className={style.rowHead}>SOW Value</div>
            <div className={style.rowValue}>
              <input
                value={projectAdditionalData?.projectSOWValue}
                onChange={(e) => {
                  setProjectAdditionalData({
                    ...projectAdditionalData,
                    projectSOWValue: e.target.value,
                  });
                  setError(false);
                }}
                type="text"
                placeholder="Enter SOW"
                className={style.rowVal}
                disabled={false}
              />
            </div>
            <div className={style.errorText}>
              {error && "Please Enter SOW value"}
            </div>
          </div>
          <div className={style.gridSubItem}>
            <div className={style.rowHead}>Total Invoices Raised</div>
            <div className={style.rowValue}>
              {projectDetails?.totalMoneyReceived
                ? roundToZeroDecimalPlaces(projectDetails?.totalMoneyReceived)
                : ""}
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className={style.rowHead}>Project Cost</div>
            <div
              className={style.rowValue}
              style={{
                visibility: projectDetails?.projectCostWithoutOverhead
                  ? "none"
                  : "hidden",
              }}
            >
              {/* <CurrencyRupeeIcon
                style={{
                  width: "14px",
                  marginBottom: "2px",
                  visibility: projectDetails?.projectCostWithoutOverhead
                    ? "none"
                    : "hidden",
                }}
              /> */}
              {projectDetails?.projectCostWithoutOverhead
                ? roundToZeroDecimalPlaces(
                    projectDetails?.projectCostWithoutOverhead
                  )
                : "aa"}
            </div>
          </div>
          <div className={style.gridSubItem} style={{ marginTop: "52px" }}>
            <div className={style.rowHead}>Additional Amount</div>
            <div className={style.rowValue}>
              <input
                value={projectAdditionalData?.otherCosts}
                onChange={(e) =>
                  setProjectAdditionalData({
                    ...projectAdditionalData,
                    otherCosts: e.target.value,
                  })
                }
                type="text"
                placeholder="Enter amount"
                className={style.rowVal}
              />
              {/* <span className={style.cmnt_icon_container}><MdComment className={style.cmnt_icon} onClick={() => setShowAddNote(true)} /></span> */}
            </div>
          </div>
          <div className={style.gridSubItem}>
            <div className={style.rowHead}>Total Money Received</div>
            <div className={style.rowValue}>
              {projectDetails?.totalMoneyReceived
                ? roundToZeroDecimalPlaces(projectDetails?.totalMoneyReceived)
                : ""}
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className={style.rowHead}>
              Project Cost <br /> (with Overhead)
            </div>
            <div className={style.rowValue}>
              {/* <CurrencyRupeeIcon
                style={{
                  width: "14px",
                  marginBottom: "2px",
                  visibility: projectDetails?.projectCostWithoutOverhead
                    ? "none"
                    : "hidden",
                }}
              /> */}
              {projectDetails?.projectCostWithOverhead
                ? roundToZeroDecimalPlaces(
                    projectDetails?.projectCostWithOverhead
                  )
                : ""}
            </div>
          </div>
          <div className={style.gridSubItem}>
            <div className={style.rowHead}>Additional Amount Note</div>
            <div className={style.rowValue}>
              <input
                style={{ padding: "5px" }}
                value={projectAdditionalData?.notesForOtherCosts}
                onChange={(e) =>
                  setProjectAdditionalData({
                    ...projectAdditionalData,
                    notesForOtherCosts: e.target.value,
                  })
                }
                type="text"
                placeholder="Enter amount"
                className={style.rowVal}
              />
              {/* <span className={style.cmnt_icon_container}><MdComment className={style.cmnt_icon} onClick={() => setShowAddNote(true)} /></span> */}
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className={style.rowHead}>Planned Project Cost</div>
            <div
              className={style.rowValue}
              style={{
                visibility: !projectDetails?.plannedProjectCost
                  ? "hidden"
                  : "none",
              }}
            >
              {/* <CurrencyRupeeIcon
                style={{
                  width: "14px",
                  marginBottom: "2px",
                  visibility: !projectDetails?.plannedProjectCost
                    ? "hidden"
                    : "none",
                }}
              /> */}
              {projectDetails?.plannedProjectCost
                ? roundToZeroDecimalPlaces(projectDetails?.plannedProjectCost)
                : "aa"}
            </div>
          </div>
          <div className={style.gridSubItem} style={{ marginTop: "52px" }}>
            <div className={style.rowHead}>Gross Margin %</div>
            <div className={style.rowValue}>
              {projectDetails?.grossMarginPercent
                ? roundToZeroDecimalPlaces(projectDetails?.grossMarginPercent) +
                  "%"
                : ""}
            </div>
          </div>
        </div>

        <div>
          <div>
            <div className={style.rowHead}>
              Planned Project Cost <br /> (with Overhead)
            </div>
            <div className={style.rowValue}>
              {/* <CurrencyRupeeIcon
                style={{
                  width: "14px",
                  marginBottom: "2px",
                  visibility: !projectDetails?.plannedProjectCost
                    ? "hidden"
                    : "none",
                }}
              /> */}
              {projectDetails?.plannedProjectCostWithOverhead
                ? roundToZeroDecimalPlaces(
                    projectDetails?.plannedProjectCostWithOverhead
                  )
                : ""}
            </div>
          </div>
          <div className={style.gridSubItem}>
            <div className={style.rowHead}>Net Margin %</div>
            <div className={style.rowValue}>
              {projectDetails?.netMarginPercent
                ? roundToZeroDecimalPlaces(projectDetails?.netMarginPercent) +
                  "%"
                : ""}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          className={style.gridSubItem}
          style={{ textAlign: "right", marginRight: "3rem" }}
        >
          <button className={style.btnCancel} onClick={handleRedirect}>
            Cancel
          </button>
          <button className={style.btnSubmit} onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

function AddAdditionalNoteModal({
  showAddNote,
  setShowAddNote,
  addAmtNote,
  setAddAmtNote,
  initialNote,
}) {
  const currNote = useRef(addAmtNote ? addAmtNote : initialNote);
  const [edit, setEdit] = useState(false);
  const handleClick = () => {
    setShowAddNote(false);
    handleEdit();
  };

  const handleCancel = () => {
    handleEdit();
    setAddAmtNote(currNote);
    setShowAddNote(false);
  };

  const handleCancelNotEdit = () => {
    handleEdit();
    setShowAddNote(false);
  };

  const handleEdit = () => {
    if (initialNote || addAmtNote) {
      setEdit(false);
    } else {
      setEdit(true);
    }
  };

  useEffect(() => {
    handleEdit();
  }, []);

  return (
    <Modal
      open={showAddNote}
      center
      onClose={() => setShowAddNote(false)}
      showCloseIcon={false}
      classNames={{
        modal: "reactmodal",
      }}
    >
      <div className={style.modal}>
        <div>
          <div className={style.rowHead}>Additional Amount Note</div>
          {!edit ? (
            <span>{addAmtNote}</span>
          ) : (
            <input
              className={style.rowValModal}
              type="text"
              placeholder="Enter note"
              value={addAmtNote}
              onChange={(e) => setAddAmtNote(e.target.value)}
            />
          )}
        </div>
        <div className={style.btnRow} style={{ textAlign: "right" }}>
          <button
            className={style.btnCancel}
            onClick={() => (edit ? handleCancel() : handleCancelNotEdit())}
          >
            Cancel
          </button>
          {!edit ? (
            <button className={style.btnSubmit} onClick={() => setEdit(true)}>
              Edit
            </button>
          ) : (
            <button className={style.btnSubmit} onClick={handleClick}>
              Submit
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
}
