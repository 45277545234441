import style from "./fixedBidProjects.module.css";
import moment from "moment";
import { useEffect, useMemo, useState, createContext, useContext } from "react";
import * as Img from "../../../../assets/index";
import { useHistory, useLocation } from "react-router-dom";
import { path } from "../../../../routes/PathNames";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import DatePicker from "react-datepicker";
import Moment from "moment/moment";
import calendarIcon from "../../../../assets/calendarIcon.png";
import React from "react";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import FilterSearchSelect from "../../../atsDashboard/commonComponent/filterSearch/FilterSearchSelect";
import { useQuery } from "react-query";
import {
  getAllProjects,
  getOverheadLists,
  getProjects,
} from "../../../../Utils/axios/pcm_Api";
import { notifyError } from "../../../atsDashboard/utils/notify";
import Loading from "../loading/loading";
import {
  addCommas,
  addCommasPCM,
  addCommasWithDecimal,
} from "../../../Appraisal_System/commonComponent/utils/AddCommas";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import Pagination from "../../../taskTracker/commonComponent/Pagination";
import PCMPagination from "../../pagination/Pagination";
import {
  localStorageGetItem,
  localStorageSetItem,
  projectCostingAndMarginAccessCode,
} from "../../../../Utils/constants";
import useValidationAccess from "../../../custumHooks/useValidationAccess";
import DropSwitchCommPCM from "../../CommonComponent/dropSwitchPCM";

const PorjectContext = createContext();

export default function FixedBidProjects() {
  const location = useLocation();
  const dt = location?.state?.dt;
  const [filter, setFilter] = useState({
    date:
      localStorageGetItem("pcm_dt") ||
      moment(dt || new Date()).format("YYYY-MM-DDTHH:mm:ss"),
    projectId: null,
  });
  const [showOverhead, setShowOverhead] = useState(true);
  const [totalCount, setTotalCount] = useState(null);
  const { checkValidateAccess } = useValidationAccess();
  const [isChecked, setIsChecked] = useState(false);

  return (
    <PorjectContext.Provider
      value={{
        filter,
        setFilter,
        showOverhead,
        setShowOverhead,
        totalCount,
        setTotalCount,
        isChecked,
        setIsChecked,
      }}
    >
      <div className={style.main}>
        {checkValidateAccess(
          projectCostingAndMarginAccessCode?.allProjects_dashboard
        ) && (
          <>
            <Header filter={filter} setFilter={setFilter} />
            <div>
              <Body filter={filter} />
            </div>
          </>
        )}
      </div>
    </PorjectContext.Provider>
  );
}

function Header() {
  const [showInDt, setShowInDt] = useState(false);
  // const [projectsOptions, setProjectOptions] = useState();
  // const { data: projectFilter } = useQuery(
  //   ["getAllProjects"],
  //   () => getAllProjects(),
  //   {
  //     refetchOnWindowFocus: false,
  //     onError: (err) => notifyError("Something went wrong!"),
  //   }
  // );
  const {
    filter,
    setFilter,
    showOverhead,
    setShowOverhead,
    totalCount,
    isChecked,
    setIsChecked,
  } = useContext(PorjectContext);

  // const transformProjectOptions = useMemo(() => {
  //   return projectFilter?.length > 0
  //     ? projectFilter.map((item) => ({
  //         label: item?.projectName,
  //         value: item?.projectId,
  //       }))
  //     : [];
  // }, [projectFilter]);

  // useEffect(() => {
  //   setProjectOptions(transformProjectOptions);
  // }, [transformProjectOptions]);

  const handleChange = (e) => {
    if (e.target.value === "withoutOverhead") {
      setShowOverhead(false);
    } else if (e.target.value === "withOverhead") {
      setShowOverhead(true);
    }
  };
  return (
    <div className={style.header}>
      <div className={`${style.heading} ${style.cmnt_icon} ${style.gap1rem}`}>
        <div>Fix Bid Projects</div>
        <div>
          <p className={style.countPCM}>
            <span style={{ fontSize: "1.235rem", fontWeight: "500" }}>
              {totalCount}
            </span>
          </p>
        </div>
        <div className={style.allProjectDropswitch}>
          <DropSwitchCommPCM
            isChecked={isChecked}
            handleChange={() => setIsChecked((prev) => !prev)}
            label="&nbsp; All"
          />
        </div>
      </div>

      <div className={style.radioGroup}>
        <div className={style.radioContainer}>
          <input
            id="withOverhead"
            value={"withOverhead"}
            type="radio"
            name="projectCosting"
            onChange={handleChange}
            checked={showOverhead}
          />
          <label htmlFor="withOverhead">With Overhead</label>
        </div>
        <div className={style.radioContainer}>
          <input
            id="withoutOverhead"
            value={"withoutOverhead"}
            type="radio"
            name="projectCosting"
            onChange={handleChange}
            checked={!showOverhead}
          />
          <label htmlFor="withoutOverhead">Without Overhead</label>
        </div>
      </div>
      <div className={style.filters}>
        <div className={style.headerDate}>
          {showInDt ? (
            <div className={style.csd}>
              <DatePicker
                dateFormat="DD MMM YY"
                startDate={null}
                value={Moment(filter.date).format("DD MMM YY")}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onChange={(date) => {
                  setFilter((prev) => ({ ...prev, date }));
                  setShowInDt(!showInDt);
                  localStorageSetItem("pcm_dt", date);
                }}
                defaultValue={null}
                customInput={<CustomInputClg />}
                style={{ backgroundColor: "#F5F6FA" }}
              />
            </div>
          ) : (
            <div className={style.headerDt}>
              {moment(filter?.date).format("DD MMM YY")}
              <span>
                <img
                  src={Img.cross}
                  width="12px"
                  height="12px"
                  alt="Cross"
                  style={{
                    cursor: "pointer",
                    marginLeft: "10px",
                    marginBottom: "4px",
                  }}
                  onClick={() => {
                    setShowInDt(!showInDt);
                  }}
                />
              </span>
            </div>
          )}
        </div>
        {/* <div>
          <FilterSearchSelect
            placeholder="Project Name"
            value={filter?.projectId}
            options={projectsOptions}
            onChange={(e) => {
              setFilter((prev) => ({ ...prev, projectId: e?.value }));
            }}
            onClose={() => {
              setFilter((prev) => ({ ...prev, projectId: null }));
            }}
          />
        </div> */}
      </div>
    </div>
  );
}

function Body() {
  const history = useHistory();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(
    location?.state?.pccurrentPage ? location?.state?.pccurrentPage : 1
  );
  const [pageCount, setPageCount] = useState(1);
  const { filter, showOverhead, setTotalCount, isChecked } =
    useContext(PorjectContext);
  const { checkValidateAccess } = useValidationAccess();

  const addSowValAccess = checkValidateAccess(
    projectCostingAndMarginAccessCode?.addSOWValue
  );

  const invoices_dashboard_access = checkValidateAccess(
    projectCostingAndMarginAccessCode?.invoices_dashboard
  );

  const { data: overheadData } = useQuery(
    ["overheadList"],
    () => getOverheadLists(),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (err) => notifyError("Something went wrong!"),
    }
  );

  const { data, isLoading } = useQuery(
    ["allProjectsList", filter, currentPage, isChecked],
    () =>
      getProjects(
        {
          ...filter,
          isSoftAllocated: false,
          date:
            moment(filter?.date)
              .format("YYYY-MM-DDTHH:mm:ss")
              .toString()
              .split("T")[0] + "T00:00:00",
          projectStatus: !isChecked ? [1] : [1, 3],
          billingModelId: [1]
        },
        currentPage,
        10
      ),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (err) => notifyError("Something went wrong!"),
    }
  );

  const handleRedirect = (id, projectName, projectDetails) => {
    setTimeout(() => {
      history.push(path.pcm_editProjectFixBid, {
        id,
        projectName,
        projectDetails: { ...projectDetails },
        dt: filter?.date,
        pccurrentPage: currentPage,
      });
    }, 0);
  };

  const handleRedirectInvoice = (id, projectName) => {
    setTimeout(() => {
      history.push(path.pcm_fixBid_invoices, {
        id,
        projectName,
        pccurrentPage: currentPage,
      });
    }, 0);
  };

  const handleRedirectProject = (id, projectName) => {
    setTimeout(() => {
      history.push(path.pcm_projectCostingInformationFixBid, { id, projectName });
    }, 0);
  };

  const tableRow = data?.projectsResponse?.map((row, index) => (
    <Row
      key={"projects" + row?.projectId + index}
      {...row}
      invoices_dashboard_access={invoices_dashboard_access}
      addSowValAccess={addSowValAccess}
      handleRedirect={() =>
        handleRedirect(row?.projectId, row?.projectName, { ...row })
      }
      handleRedirectInvoice={() =>
        handleRedirectInvoice(row?.projectId, row?.projectName)
      }
      handleRedirectProject={() =>
        handleRedirectProject(row?.projectId, row?.projectName)
      }
      showOverhead={showOverhead}
    />
  ));

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  useEffect(() => {
    setTotalCount(data?.projectsCount);
    setPageCount(data?.projectsCount / 10);
  }, [data]);

  return (
    <div className={style.body}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {data?.projectsCount < 0 || !data || data?.message ? (
            <div className={style.noProjectData}>
              {data?.message || "No Projects Available"}
            </div>
          ) : (
            <div className={style.tableContainer}>
              <table>
                <thead>
                  <tr className={style.tHeading}>
                    <th>Data as of Date</th>
                    <th style={{ width: "9rem" }}>Name</th>
                    <th style={{ width: "6rem" }}>
                      Project Cost <br /> (in lakhs)
                    </th>
                    <th style={{ width: "7rem" }} className={style.ppc}>
                      Planned Project Cost (in lakhs)
                    </th>
                    <th style={{ width: "6rem" }}>
                      SOW Value <br /> (in lakhs)
                    </th>
                    <th style={{ width: "6rem" }}>Gross Margin %</th>
                    <th style={{ width: "6rem" }}>Net Margin %</th>
                    <th style={{ width: "7rem" }} className={style.ppc}>
                      Total Invoices Raised
                    </th>
                    <th style={{ width: "7rem" }} className={style.ppc}>
                      Total Money Received
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{tableRow}</tbody>
              </table>
            </div>
          )}
        </>
      )}
      {!isLoading && data?.projectsCount > 10 && (
        <div
          style={{ marginTop: "1.2rem" }}
          className={style.paginationContainer}
        >
          <PCMPagination
            pagesCount={pageCount}
            pageSize="10"
            onPageChange={handlePageChange}
            currentPage={currentPage}
          />
        </div>
      )}
    </div>
  );
}

function Row(props) {
  const [addGross, setAddGross] = useState(true);
  const [addTotalinvoice, setAddTotalinvoice] = useState(true);

  const { checkValidateAccess } = useValidationAccess();

  const convertToLakhs = (number) => {
    if (number === null || number === undefined) return null;
    const decimalsValue = roundToTwoDecimalPlaces(number / 100000);
    return decimalsValue === "0" ? "-" : decimalsValue;
  };

  const roundToZeroDecimalPlaces = (number) => {
    const tempVal = addCommasPCM(number.toFixed(0));
    return tempVal === "0" ? "-" : tempVal;
  };

  const tempProjectCost = props?.showOverhead
    ? props?.projectCostWithOverhead
    : props?.projectCostWithoutOverhead;
  const tempPlannedProjectCost = props?.showOverhead
    ? props?.plannedProjectCostWithOverhead
    : props?.plannedProjectCost;

  const projectCostWOverhead = convertToLakhs(
    (tempProjectCost ? tempProjectCost : 0) +
      (props?.additionalCostOfAProject ? props?.additionalCostOfAProject : 0)
  );
  const plannedProjectCost = convertToLakhs(
    (tempPlannedProjectCost ? tempPlannedProjectCost : 0) +
      (props?.additionalCostOfAProject ? props?.additionalCostOfAProject : 0)
  );
  const projectSOWValue = convertToLakhs(props?.projectSOWValue || 0);
  const grossMarginPercent = roundToZeroDecimalPlaces(
    props?.grossMarginPercent || 0
  );
  const netMarginPercent = roundToZeroDecimalPlaces(
    props?.netMarginPercent || 0
  );
  const totalInvoicesRaised = roundToZeroDecimalPlaces(
    props?.totalInvoicesRaised || 0
  );
  const totalMoneyReceived = roundToZeroDecimalPlaces(
    props?.totalMoneyReceived || 0
  );

  function roundToOneDecimalPlaces(number) {
    const tempVal = addCommasWithDecimal(number.toFixed(1), true);
    return tempVal === "0" ? "-" : tempVal;
  }

  function roundToTwoDecimalPlaces(number) {
    const tempVal = addCommasWithDecimal(number.toFixed(2));
    return tempVal === "0" ? "-" : tempVal;
  }

  useEffect(() => {
    if (!props?.projectSOWValue || !props?.grossMarginPercent)
      setAddGross(false);
    if (!props?.totalInvoicesRaised) setAddTotalinvoice(false);
  }, []);

  return (
    <tr className={style.tRow}>
      <td>
        {props?.dataAsOfDate && Moment(props?.dataAsOfDate).format("DD MMM YY")}
      </td>
      <td
        style={{ width: "9rem" }}
        className={style.addText}
        title={props?.projectName?.length > 36 && props?.projectName}
        onClick={props?.handleRedirectProject}
      >
        {props?.projectName?.length > 36
          ? props?.projectName.substr(0, 37) + "..."
          : props?.projectName}
      </td>
      <td style={{ width: "6rem" }}>
        {projectCostWOverhead ? (
          <>{projectCostWOverhead?.length ? projectCostWOverhead : ""}</>
        ) : (
          ""
        )}
      </td>
      <td
        style={{ width: "7rem" }}
        title={plannedProjectCost?.length > 9 && plannedProjectCost}
      >
        {plannedProjectCost ? (
          <>
            {plannedProjectCost?.length > 9
              ? plannedProjectCost.substr(0, 10) + "..."
              : plannedProjectCost}
          </>
        ) : (
          ""
        )}
      </td>
      <td
        style={{ width: "6rem" }}
        title={projectSOWValue?.length > 6 && projectSOWValue}
      >
        {projectSOWValue
          ? projectSOWValue?.length > 6
            ? projectSOWValue?.substr(0, 7) + "..."
            : projectSOWValue
          : ""}
      </td>
      <td
        style={{ width: "6rem" }}
        title={grossMarginPercent?.length > 9 && grossMarginPercent + "%"}
      >
        {grossMarginPercent
          ? grossMarginPercent?.length > 9
            ? grossMarginPercent?.substr(0, 10) + "..."
            : grossMarginPercent !== "-"
            ? grossMarginPercent + "%"
            : grossMarginPercent
          : ""}
      </td>
      <td
        style={{ width: "6rem" }}
        title={netMarginPercent?.length > 9 && netMarginPercent + "%"}
      >
        {netMarginPercent
          ? netMarginPercent?.length > 9
            ? netMarginPercent.substr(0, 9) + "..."
            : netMarginPercent !== "-"
            ? netMarginPercent + "%"
            : netMarginPercent
          : ""}
      </td>
      <td
        style={{ width: "7rem" }}
        title={totalInvoicesRaised?.length > 15 && totalInvoicesRaised}
      >
        {totalInvoicesRaised?.length > 15
          ? totalInvoicesRaised.substr(0, 16) + "..."
          : totalInvoicesRaised}
      </td>
      <td
        style={{ width: "7rem" }}
        title={totalMoneyReceived?.length > 15 && totalMoneyReceived}
      >
        {totalMoneyReceived?.length > 15
          ? totalMoneyReceived.substr(0, 16) + "..."
          : totalMoneyReceived}
      </td>
      <td style={{ display: "flex" }}>
        {props?.invoices_dashboard_access && (
          <div style={{ marginRight: "0.2rem" }}>
            <OverlayTrigger
              placement="auto"
              overlay={<Tooltip id="tooltip-example">Add Invoice</Tooltip>}
            >
              <FormatItalicIcon
                className={style.editInvoice}
                onClick={props?.handleRedirectInvoice}
              />
            </OverlayTrigger>
          </div>
        )}
        {props?.addSowValAccess && (
          <div>
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip id="tooltip-example">
                  Edit SOW value & Additional amt.
                </Tooltip>
              }
            >
              <AddCircleRoundedIcon
                className={style.editInvoice}
                onClick={props?.handleRedirect}
              />
            </OverlayTrigger>
          </div>
        )}
      </td>
    </tr>
  );
}

const CustomInputClg = React.forwardRef((props, ref) => {
  return (
    <div onClick={props.onClick}>
      <label ref={ref} style={{ padding: "8px 8px 8px 5px", fontWeight: 600 }}>
        {props.value || props.placeholder}
      </label>
      <img
        type="image/svg+xml"
        width="20px"
        style={{ position: "absolute", right: "3%", top: "19%", zIndex: "0" }}
        src={calendarIcon}
        onClick={props.onClick}
        alt=""
      />
    </div>
  );
});
