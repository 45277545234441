import React from 'react';
import Moment from "moment";
import "./Timeline.css";
import info from "../../../../assets/infoButton.svg";
import moment from "moment";

const Timeline = ({userDetails}) => {
  return (
    <div className="timeline" style={{width: "23rem"}}>
        {/* Vertical Line */}
        <div className="timeline-line"></div>

        <div className="timeline-item">
            <div className="circle green"></div>
            <div className="content">
            <div className="title" style={{color: "green"}}>Joined: </div>
            <div className="date">{moment(userDetails?.dateOfJoining).format(
            moment(userDetails?.dateOfJoining).year() === moment().year() ? 'DD MMM' : 'DD MMM YY')}</div>
            </div>
        </div>

        {userDetails?.confirmationDate && <div className="timeline-item">
            <div className="circle gray"></div>
            <div className="content">
            <div className="title">Confirmation Date (<span>{userDetails?.period}</span>): </div>
            <div className="date">{moment(userDetails?.confirmationDate).format(
            moment(userDetails?.confirmationDate).year() === moment().year() ? 'DD MMM' : 'DD MMM YY')}</div>
            </div>
        </div>}

        {userDetails?.actualConfirmationDate && <div className="timeline-item">
            <div className="circle gray"></div>
            <div className="content">
            <div className="title">Actual Confirmation Date: </div>
            <div className="date">{moment(userDetails?.actualConfirmationDate).format(
                moment(userDetails?.actualConfirmationDate).year() === moment().year() ? 'DD MMM' : 'DD MMM YY')} &nbsp;
                {userDetails?.delayReason && <span>
                    <img style={{width: "1rem", cursor: "pointer", marginTop: "-5px"}} 
                    title={userDetails?.delayReason} 
                    src={info} 
                    alt="Img Not Available"/>
                </span>}
            </div>
            </div>
        </div>}

        {userDetails?.resignationDate && <div className="timeline-item">
            <div className="circle gray"></div>
            <div className="content">
            <div className="title">Resignation: </div>
            <div className="date">{moment(userDetails?.resignationDate).format(
            moment(userDetails?.resignationDate).year() === moment().year() ? 'DD MMM' : 'DD MMM YY')}</div>
            </div>
        </div>}

        {userDetails?.unResignationDate ? (
            <div className="timeline-item">
                <div className="circle gray"></div>
                <div className="content">
                    <div className="title">Resigned Revoke: </div>
                    <div className="date">
                        {moment(userDetails?.unResignationDate).format(
                            moment(userDetails?.unResignationDate).year() === moment().year() ? 'DD MMM' : 'DD MMM YY'
                        )}
                    </div>
                </div>
            </div>
        ) : userDetails?.expectedLWD ? (
            <div className="timeline-item">
                <div className="circle gray"></div>
                <div className="content">
                    <div className="title">Expected LWD (<span>{userDetails?.noticePeriod}</span>): </div>
                    <div className="date">
                        {moment(userDetails?.expectedLWD).format(
                            moment(userDetails?.expectedLWD).year() === moment().year() ? 'DD MMM' : 'DD MMM YY'
                        )}
                    </div>
                </div>
            </div>
        ) : null}

        {userDetails?.retainDate && <div className="timeline-item">
            <div className="circle gray"></div>
            <div className="content">
            <div className="title">Retain: </div>
            <div className="date">{moment(userDetails?.retainDate).format(
            moment(userDetails?.retainDate).year() === moment().year() ? 'DD MMM' : 'DD MMM YY')}</div>
            </div>
        </div>}

        {userDetails?.exitPlanDate && <div className="timeline-item">
            <div className="circle gray"></div>
            <div className="content">
            <div className="title">Exit Planned Date: </div>
            <div className="date">{moment(userDetails?.exitPlanDate).format(
                moment(userDetails?.exitPlanDate).year() === moment().year() ? 'DD MMM' : 'DD MMM YY')} &nbsp;
                {userDetails?.exitComment && <span>
                    <img style={{width: "1rem", cursor: "pointer", marginTop: "-5px"}} 
                    title={userDetails?.exitComment} 
                    src={info} 
                    alt="Img Not Available"/>
                </span>}
            </div>
            </div>
        </div>}

        {userDetails?.exitDecisionDate && <div className="timeline-item">
            <div className="circle gray"></div>
            <div className="content">
            <div className="title">Exit Decision Date: </div>
            <div className="date">{moment(userDetails?.exitDecisionDate).format(
                moment(userDetails?.exitDecisionDate).year() === moment().year() ? 'DD MMM' : 'DD MMM YY')}</div>
            </div>
        </div>}

        {userDetails?.lastWorkingDayDate && <div className="timeline-item">
            <div className="circle red"></div>
            <div className="content">
            <div className="title" style={{color: "red"}}>Exit(LWD): </div>
            <div className="date">{moment(userDetails?.lastWorkingDayDate).format(
                moment(userDetails?.lastWorkingDayDate).year() === moment().year() ? 'DD MMM' : 'DD MMM YY')}</div>
            </div>
        </div>}
    </div>
  );
};

export default Timeline;

