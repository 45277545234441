import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import style from "./CommentHistory.module.css";
import AddComment from "../AddComment/AddComment";
import deleteImg from "../../../../../assets/icons8-delete-24.png";
import editImg from "../../../../../assets/icons8-edit-30.png";
import { getAllPositionComments, updatePositionComment, getTagsByPosition, getAllTags, addCommentsForPosition,
addTag, deletePositionTag, addTagsToPosition, getTagsByCandidate, deleteCandidateTag, positionCommentMST,
addTagsToCandidate } from "../../../../../Utils/axios/api";
import { useMutation, useQuery } from "react-query";
import moment from "moment";
import { notifyError, notifySuccess } from "../../../utils/notify";
import { employeeId } from "../../../../../Utils/constants";
import { Modal } from "react-responsive-modal";
import CreatableSelect from "react-select/creatable";
import ATSButton from "../../../customTooltip/ATSButton";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";
import zIndex from "@mui/material/styles/zIndex";
export const customSearch = {
  control: (base, { isDisabled }) => ({
    // margin: "0 7px",
    ...base,
    borderRadius: "10px",
    // padding: "0 10px 0 10px",
    border: "1px solid #00000061",
    cursor: "pointer",
    backgroundColor: isDisabled ? "#e0dcdc" : "#f5f6fa",
    // marginRight: "6px",
  }),
  placeholder: () => ({
    // display: "none",
    marginTop: "-30px",
    color: "hsl(0, 0%, 5%);",
    fontSize: "15px"
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#84b47e" : state.isSelected ? "#84b47e" : "white",
    color: state.isSelected ? "white" : "black",
    fontWeight: "600",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#84b47e",  // Ensure this color is applied
      color: "white",
    },
  }),
  
  // dropdown width
  menu: (provided) => ({
    ...provided,
    borderRadius: "10px",
    position: "absolute",
    width: "95%",
    marginLeft: "0.6rem",
    zIndex: "999",
    maxHeight: "300px",
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 })
};

const getCommentForPosition = (positionId) => {
  const existingComments = JSON.parse(localStorage.getItem('unsavedComments')) || [];
  const commentData = existingComments.find(c => c.positionId === positionId);
  return commentData ? commentData.comment : '';
};


export function TagHistory({
  setShowTagModal,
  positionId,
  positionName,
  myClientName,
  positionCodee,
}) {
  const [showDeleteModalTag, setShowDeleteModalTag] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [showTags, setShowTags] = useState(false);
  const toggleTags = () => {
    setShowTags((prev) => !prev);
  };

  const { data: tagData, isLoading: tagLoading, refetch: refetchTag } = useQuery(
    "getTagsByPosition",
    () => getTagsByPosition(positionId),
    { refetchOnWindowFocus: false, retry: false }
  );

  const { data: getAllTagData, refetch: refetchAllTags } = useQuery(
    ["getAllTags"],
    () => getAllTags("Position"),
    { refetchOnWindowFocus: false }
  );
  const getAllTagOption = getAllTagData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const updateCandidateTagMutation = useMutation(deletePositionTag, {
    onMutate: () => {},
    onSuccess: () => {
      notifySuccess("Tag Deleted Successfully");
      refetchTag();
    },
    onError: (err) => {
      console.error(err);
      notifyError("Something went wrong");
    },
  });

  const createTagMutation = useMutation(addTag, {
    onMutate: () => {},
    onSuccess: (newTag) => {
      // notifySuccess("Tag created successfully!");
      refetchAllTags();
    },
    onError: (err) => {
      console.error(err);
      notifyError("Something went wrong");
    },
  });

  const handleCreateTag = (inputValue) => {
    createTagMutation.mutate(
      { tagName: inputValue, tagType: "Position" },
      {
        onSuccess: (newTag) => {
          addTagsForPositionMutation.mutate({
            positionId: positionId,
            tagCreatedBy: employeeId,
            tagId: newTag.id, 
          });
        },
        onError: (err) => {
          console.error(err);
          notifyError("Something went wrong while creating the tag.");
        },
      }
    );
  };

  const deleteComment = () => {
    if (selectedTag) {
      console.log("selectedTag", selectedTag);
      updateCandidateTagMutation.mutate({
        tagId: selectedTag.id,
        empId: selectedTag.createdByUserId,
      });
      setShowDeleteModalTag(false);
    }
  };

  const cancelDelete = () => {
    setShowDeleteModalTag(false);
    setSelectedTag(null);
  };

  const handleDeleteTag = (tag) => {
    setShowDeleteModalTag(true);
    setSelectedTag(tag);
  };

  const addTagsForPositionMutation = useMutation(addTagsToPosition, {
    onMutate: () => {},
    onSuccess: (res) => {
      if (res.responseStatus.statusCode === 1) {
        notifySuccess("Tag Added Successfully");
      } else {
        notifyError(res.responseData.message);
      }
      refetchTag();
    },
    onError: (err) => {
      notifyError("Something went wrong");
    },
  });

  return (
    <div>
      <DeleteConfirmationModelTag
        showDeleteModal={showDeleteModalTag}
        deleteComment={deleteComment}
        cancelDelete={cancelDelete}
      />
      <div className={style.description_box1}>
        <div style={{display: "flex", alignItems: "center", justifyContent:"space-between"}}>
          <div></div>
          <div className={style.span2} style={{marginBottom:"5px"}}>
            {positionName === undefined && myClientName === undefined ? (
              <p></p>
            ) : positionName && myClientName ? (
              <p>
                {positionCodee} - {positionName} ({myClientName})
              </p>
            ) : positionCodee ? (
              <p>
                {positionCodee} - {positionName}
              </p>
            ) : (
              <p>{positionName}</p>
            )}
          </div>
          <div title="Click Here to Expand" onClick={toggleTags} style={{cursor: "pointer", marginRight: "5px"}}>
            {showTags ? (<IoIosArrowUp style={{color: "#65a15e", width: "1.5rem", height: "1.5rem"}}/>) : 
            (<IoIosArrowDown style={{color: "#65a15e", width: "1.5rem", height: "1.5rem"}}/>)}
          </div>
        </div>
        <div style={{padding: "5px 5px", maxHeight:"8rem", overflowY:"auto"}}>{!tagLoading && tagData?.length > 0 ? (
          tagData?.map((tag, index) => (!(tag?.isDeleted) && <ATSButton styles={{backgroundColor:"white", color: "black", border: "1.5px solid #65a15e", 
          borderRadius: "8px", padding: "5px", textAlign: "start", fontSize:"14px", margin: "2px", overflowWrap: "anywhere"}}>
            {tag?.tagName}
          </ATSButton>))) : (<div style={{padding: "0px 9px"}}>
            <p style={{fontSize:"18px", fontWeight:"600", color:"grey", textAlign: "center"}}>Currently No Tags</p>
          </div>)}
        </div>
        <div>
          {/* <div title="Click Here" style={{ cursor: "pointer", display: "flex", justifyContent: "space-between", padding: "10px 10px", 
          backgroundColor: "#FAFAFA", marginTop: "10px",}} onClick={toggleTags}>
            <div className={style.historyDiv}>History</div>
            <div>
              {showTags ? (<IoIosArrowUp style={{color: "#65a15e", width: "2rem", height: "2rem"}}/>) : 
              (<IoIosArrowDown style={{color: "#65a15e", width: "2rem", height: "2rem"}}/>)}
            </div>
          </div> */}
          {<div className={`${style.collapseContent} ${showTags ? style.collapseContentShow : ""}`}>
            <div
              className={style.maindivContent}
              style={{ width: "25rem", margin: "auto", marginTop: "10px" }}
            >
              <CreatableSelect
                styles={customSearch}
                isClearable
                placeholder="Select Tag..."
                options={getAllTagOption}
                onCreateOption={handleCreateTag} 
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    addTagsForPositionMutation.mutate({
                      positionId: positionId,
                      tagCreatedBy: employeeId, 
                      tagId: selectedOption.value,
                    });
                  }
                }}
                value={null}
              />
            </div>
            <div
              className={style.original_table}
              style={{
                minHeight: "3rem",
                marginTop: "7px",
                maxHeight: "17rem",
                overflowY: "auto",
              }}
            >
              <div className={style.tableBackground}>
                <Table borderless size="sm" style={{ fontSize: "14.5px"}}>
                  <thead
                    style={{
                      position: "sticky",
                      top: "0",
                    }}
                  >
                    <tr>
                      <th style={{ padding: "10px", width: "3.5rem" }}>Date</th>
                      <th style={{ padding: "10px", minWidth: "100px" }}>Added By</th>
                      <th></th>
                      <th style={{ padding: "10px" }}>Tag</th>
                      <th style={{ padding: "10px", minWidth: "75px" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!tagLoading && tagData?.length > 0 ? (
                      tagData.map((tag, index) => (
                        <tr key={index}>
                          <td style={{ paddingLeft: "10px", minWidth: "4rem"}}>
                            {tag?.createdOn
                              ? moment(tag.createdOn).year() === moment().year()
                                ? moment(tag.createdOn).format("DD MMM")
                                : moment(tag.createdOn).format("DD MMM YY")
                              : null}
                          </td>
                          <td
                            style={{
                              paddingLeft: "10px",
                              minWidth: "170px",
                              width: "170px",
                            }}
                          >
                            {tag?.createdBy}
                          </td>
                          <td style={{width: "2rem"}}>
                            {tag?.isDeleted && <MdDeleteForever style={{color: "red"}}/>}
                          </td>
                          <td
                            style={{
                              paddingLeft: "10px",
                              whiteSpace: "pre-wrap",
                              overflowWrap: "anywhere",
                            }}
                          >
                            {tag?.tagName}
                          </td>
                          {!(tag?.isDeleted) && (
                            <td
                              style={{
                                paddingLeft: "10px",
                                minWidth: "75px",
                              }}
                            >
                              <div className={style.buttonDelete} title="Delete"
                                onClick={() => handleDeleteTag(tag)}
                                onKeyDown={() => handleDeleteTag(tag)}
                                tabIndex={0}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 69 14"
                                  className={`${style.svgIcon} ${style.binTop}`}
                                >
                                  <g clip-path="url(#clip0_35_24)">
                                    <path
                                      fill="black"
                                      d="M20.8232 2.62734L19.9948 4.21304C19.8224 4.54309 19.4808 4.75 19.1085 4.75H4.92857C2.20246 4.75 0 6.87266 0 9.5C0 12.1273 2.20246 14.25 4.92857 14.25H64.0714C66.7975 14.25 69 12.1273 69 9.5C69 6.87266 66.7975 4.75 64.0714 4.75H49.8915C49.5192 4.75 49.1776 4.54309 49.0052 4.21305L48.1768 2.62734C47.3451 1.00938 45.6355 0 43.7719 0H25.2281C23.3645 0 21.6549 1.00938 20.8232 2.62734ZM64.0023 20.0648C64.0397 19.4882 63.5822 19 63.0044 19H5.99556C5.4178 19 4.96025 19.4882 4.99766 20.0648L8.19375 69.3203C8.44018 73.0758 11.6746 76 15.5712 76H53.4288C57.3254 76 60.5598 73.0758 60.8062 69.3203L64.0023 20.0648Z"
                                    ></path>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_35_24">
                                      <rect fill="white" height="14" width="69"></rect>
                                    </clipPath>
                                  </defs>
                                </svg>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 69 57"
                                  className={`${style.svgIcon} ${style.binBottom}`}
                                >
                                  <g clip-path="url(#clip0_35_22)">
                                    <path
                                      fill="black"
                                      d="M20.8232 -16.3727L19.9948 -14.787C19.8224 -14.4569 19.4808 -14.25 19.1085 -14.25H4.92857C2.20246 -14.25 0 -12.1273 0 -9.5C0 -6.8727 2.20246 -4.75 4.92857 -4.75H64.0714C66.7975 -4.75 69 -6.8727 69 -9.5C69 -12.1273 66.7975 -14.25 64.0714 -14.25H49.8915C49.5192 -14.25 49.1776 -14.4569 49.0052 -14.787L48.1768 -16.3727C47.3451 -17.9906 45.6355 -19 43.7719 -19H25.2281C23.3645 -19 21.6549 -17.9906 20.8232 -16.3727ZM64.0023 1.0648C64.0397 0.4882 63.5822 0 63.0044 0H5.99556C5.4178 0 4.96025 0.4882 4.99766 1.0648L8.19375 50.3203C8.44018 54.0758 11.6746 57 15.5712 57H53.4288C57.3254 57 60.5598 54.0758 60.8062 50.3203L64.0023 1.0648Z"
                                    ></path>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_35_22">
                                      <rect fill="white" height="57" width="69"></rect>
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                            </td>
                          )}{/* <td style={{ paddingLeft: "10px", minWidth: "75px" }}></td> */}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="5"
                          style={{
                            fontWeight: "600",
                            fontSize: "14px",
                            padding: "10px",
                            textAlign: "center",
                            backgroundColor: "#FAFAFA"
                          }}
                        >
                          No Tags Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
}

export function TagHistoryCandidates({
  candidateId,
  setShowTagModal,
  myClientName,
  myCandidateName,
}) {
  const [showDeleteModalTag, setShowDeleteModalTag] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [showTags, setShowTags] = useState(false);
  const toggleTags = () => {
    setShowTags((prev) => !prev);
  };

  const { data: tagData, isLoading: tagLoading, refetch: refetchTag } = useQuery(
    "getTagsByCandidate",
    () => getTagsByCandidate(candidateId),
    { refetchOnWindowFocus: false, retry: false }
  );

  const { data: getAllTagData, refetch: refetchAllTags } = useQuery(
    ["getAllTags"],
    () => getAllTags("Candidate"),
    { refetchOnWindowFocus: false }
  );
  const getAllTagOption = getAllTagData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const updateCandidateTagMutation = useMutation(deleteCandidateTag, {
    onMutate: () => {},
    onSuccess: () => {
      notifySuccess("Tag Deleted Successfully");
      refetchTag();
    },
    onError: (err) => {
      console.error(err);
      notifyError("Something went wrong");
    },
  });

  const createTagMutation = useMutation(addTag, {
    onMutate: () => {},
    onSuccess: (newTag) => {
      // notifySuccess("Tag created successfully!");
      refetchAllTags();
    },
    onError: (err) => {
      console.error(err);
      notifyError("Something went wrong");
    },
  });
  const handleCreateTag = (inputValue) => {
    createTagMutation.mutate(
      { tagName: inputValue, tagType: "Candidate" },
      {
        onSuccess: (newTag) => {
          addTagsForPositionMutation.mutate({
            candidateId: candidateId,
            tagCreatedBy: employeeId, 
            tagId: newTag.id,
          });
        },
        onError: (err) => {
          console.error(err);
          notifyError("Something went wrong while creating the tag.");
        },
      }
    );
  };

  const deleteComment = () => {
    if (selectedTag) {
      console.log("selectedTag", selectedTag);
      updateCandidateTagMutation.mutate({
        tagId: selectedTag.id,
        empId: selectedTag.createdByUserId,
      });
      setShowDeleteModalTag(false);
    }
  };

  const cancelDelete = () => {
    setShowDeleteModalTag(false);
    setSelectedTag(null);
  };

  const handleDeleteTag = (tag) => {
    setShowDeleteModalTag(true);
    setSelectedTag(tag);
  };

  const addTagsForPositionMutation = useMutation(addTagsToCandidate, {
    onMutate: () => {},
    onSuccess: (res) => {
      if (res.responseStatus.statusCode === 1) {
        notifySuccess("Tag Added Successfully");
      } else {
        notifyError(res.responseData.message);
      }
      refetchTag();
    },
    onError: (err) => {
      notifyError("Something went wrong");
    },
  });


  return (
    <div>
      <DeleteConfirmationModelTag
        showDeleteModal={showDeleteModalTag}
        deleteComment={deleteComment}
        cancelDelete={cancelDelete}
      />
      <div className={style.description_box1}>
        <div style={{display: "flex", alignItems: "center", justifyContent:"space-between"}}>
          <div></div>
          <div className={style.span2} style={{marginBottom:"5px"}}>
            {myCandidateName === undefined && myClientName === undefined ? (
              <p></p>
            ) : myCandidateName ? (
              <p>
                {myCandidateName}
              </p>
            ) : (
              <p></p>
            )}
          </div>
          <div title="Click Here to Expand" onClick={toggleTags} style={{cursor: "pointer", marginRight: "5px"}}>
            {showTags ? (<IoIosArrowUp style={{color: "#65a15e", width: "1.5rem", height: "1.5rem"}}/>) : 
            (<IoIosArrowDown style={{color: "#65a15e", width: "1.5rem", height: "1.5rem"}}/>)}
          </div>
        </div>
        <div style={{padding: "5px 5px", maxHeight:"8rem", overflowY:"auto"}}>{!tagLoading && tagData?.length > 0 ? (
          tagData?.map((tag, index) => (!(tag?.isDeleted) && <ATSButton styles={{backgroundColor:"white", color: "black", border: "1.5px solid #65a15e", 
          borderRadius: "8px", padding: "5px", textAlign: "start", fontSize:"14px", margin: "2px", overflowWrap: "anywhere"}}>
            {tag?.tagName}
          </ATSButton>))) : (<div style={{padding: "0px 9px"}}>
            <p style={{fontSize:"18px", fontWeight:"600", color:"grey", textAlign: "center"}}>Currently No Tags</p>
          </div>)}
        </div>
        <div style={{backgroundColor: "#FAFAFA !important"}}>
          {/* <div title="Click Here" style={{ cursor: "pointer", display: "flex", justifyContent: "space-between", padding: "10px 10px", 
          backgroundColor: "#FAFAFA", marginTop: "10px",}} onClick={toggleTags}>
            <div className={style.historyDiv}>History</div>
            <div>
              {showTags ? (<MdOutlineKeyboardDoubleArrowUp style={{color: "#65a15e", width: "2rem", height: "2rem"}}/>) : 
              (<MdOutlineKeyboardDoubleArrowDown style={{color: "#65a15e", width: "2rem", height: "2rem"}}/>)}
            </div>
          </div> */}
          {<div className={`${style.collapseContent} ${showTags ? style.collapseContentShow : ""}`}>
            <div
              className={style.maindivContent}
              style={{ width: "25rem", margin: "auto", marginTop: "10px" }}
            >
              <CreatableSelect
                styles={customSearch}
                placeholder="Select Tag..."
                isClearable
                value={null}
                options={getAllTagOption}
                onCreateOption={handleCreateTag} 
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    addTagsForPositionMutation.mutate({
                      candidateId: candidateId,
                      tagCreatedBy: employeeId, 
                      tagId: selectedOption.value,
                    });
                  }
                }}
              />
            </div>
            <div
              className={style.original_table}
              style={{
                minHeight: "3rem",
                marginTop: "7px",
                maxHeight: "23rem",
                overflowY: "auto",
              }}
            >
              <div className={style.tableBackground} >
                <Table borderless size="sm" style={{ fontSize: "14.5px"}}>
                  <thead
                    style={{
                      position: "sticky",
                      top: "-5px",
                    }}
                  >
                    <tr>
                      <th style={{ padding: "10px", width: "3.5rem" }}>Date</th>
                      <th style={{ padding: "10px", minWidth: "100px" }}>Added By</th>
                      <th></th>
                      <th style={{ padding: "10px" }}>Tag</th>
                      <th style={{ padding: "10px", minWidth: "75px" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!tagLoading && tagData?.length > 0 ? (
                      tagData.map((tag, index) => (
                        <tr key={index}>
                          <td style={{ paddingLeft: "10px", minWidth: "4rem"}}>
                            {tag?.createdOn
                              ? moment(tag.createdOn).year() === moment().year()
                                ? moment(tag.createdOn).format("DD MMM")
                                : moment(tag.createdOn).format("DD MMM YY")
                              : null}
                          </td>
                          <td
                            style={{
                              paddingLeft: "10px",
                              minWidth: "170px",
                              width: "170px",
                            }}
                          >
                            {tag?.createdBy}
                          </td>
                          <td style={{width: "2rem"}}>
                            {tag?.isDeleted && <MdDeleteForever style={{color: "red"}}/>}
                          </td>
                          <td
                            style={{
                              paddingLeft: "10px",
                              whiteSpace: "pre-wrap",
                              overflowWrap: "anywhere",
                            }}
                          >
                            {tag?.tagName}
                          </td>
                          {!(tag?.isDeleted) && (
                            <td
                              style={{
                                paddingLeft: "10px",
                                minWidth: "75px",
                              }}
                            >
                              <div className={style.buttonDelete} title="Delete"
                                onClick={() => handleDeleteTag(tag)}
                                onKeyDown={() => handleDeleteTag(tag)}
                                tabIndex={0}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 69 14"
                                  className={`${style.svgIcon} ${style.binTop}`}
                                >
                                  <g clip-path="url(#clip0_35_24)">
                                    <path
                                      fill="black"
                                      d="M20.8232 2.62734L19.9948 4.21304C19.8224 4.54309 19.4808 4.75 19.1085 4.75H4.92857C2.20246 4.75 0 6.87266 0 9.5C0 12.1273 2.20246 14.25 4.92857 14.25H64.0714C66.7975 14.25 69 12.1273 69 9.5C69 6.87266 66.7975 4.75 64.0714 4.75H49.8915C49.5192 4.75 49.1776 4.54309 49.0052 4.21305L48.1768 2.62734C47.3451 1.00938 45.6355 0 43.7719 0H25.2281C23.3645 0 21.6549 1.00938 20.8232 2.62734ZM64.0023 20.0648C64.0397 19.4882 63.5822 19 63.0044 19H5.99556C5.4178 19 4.96025 19.4882 4.99766 20.0648L8.19375 69.3203C8.44018 73.0758 11.6746 76 15.5712 76H53.4288C57.3254 76 60.5598 73.0758 60.8062 69.3203L64.0023 20.0648Z"
                                    ></path>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_35_24">
                                      <rect fill="white" height="14" width="69"></rect>
                                    </clipPath>
                                  </defs>
                                </svg>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 69 57"
                                  className={`${style.svgIcon} ${style.binBottom}`}
                                >
                                  <g clip-path="url(#clip0_35_22)">
                                    <path
                                      fill="black"
                                      d="M20.8232 -16.3727L19.9948 -14.787C19.8224 -14.4569 19.4808 -14.25 19.1085 -14.25H4.92857C2.20246 -14.25 0 -12.1273 0 -9.5C0 -6.8727 2.20246 -4.75 4.92857 -4.75H64.0714C66.7975 -4.75 69 -6.8727 69 -9.5C69 -12.1273 66.7975 -14.25 64.0714 -14.25H49.8915C49.5192 -14.25 49.1776 -14.4569 49.0052 -14.787L48.1768 -16.3727C47.3451 -17.9906 45.6355 -19 43.7719 -19H25.2281C23.3645 -19 21.6549 -17.9906 20.8232 -16.3727ZM64.0023 1.0648C64.0397 0.4882 63.5822 0 63.0044 0H5.99556C5.4178 0 4.96025 0.4882 4.99766 1.0648L8.19375 50.3203C8.44018 54.0758 11.6746 57 15.5712 57H53.4288C57.3254 57 60.5598 54.0758 60.8062 50.3203L64.0023 1.0648Z"
                                    ></path>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_35_22">
                                      <rect fill="white" height="57" width="69"></rect>
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                            </td>
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="5"
                          style={{
                            fontWeight: "600",
                            fontSize: "14px",
                            padding: "10px",
                            textAlign: "center",
                          }}
                        >
                          No Tags Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
}

function CommentHistory({
  candidateId,
  setShowCommentModal,
  closeModal,
  positionId,
  positionName,
  myClientName,
  handleAddComment,
  getCommentForPosition,
  positionCodee
}) {
  console.log("Step 1 : Rendering CommentHistory for positionId:", positionId);
  const [obj, setObj] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const [showAddComment, setShowAddComment] = useState(false);

  const { data: CommentData, isLoading: commentLoading, refetch: refetchComments } = useQuery(
    "getAllPositionComments",
    () => getAllPositionComments(positionId),
    { refetchOnWindowFocus: false, retry: false }
  );
  console.log("Step 2 : Fetching all position comments...");

  const { data: preDefinedComment, isLoading: preDefinedCommentLoading, refetch: refetchPreDefinedComment } = useQuery(
    ["positionCommentMST"], positionCommentMST,
    { refetchOnWindowFocus: false, retry: false }
  );
  console.log("Step 3: Fetching predefined comments...");

  const updateCandidateCommentMutation = useMutation(updatePositionComment, {
    onMutate: () => { },
    onSuccess: () => {
      notifySuccess("Comment Deleted Successfully");
      console.log("Step 14: Comment deleted successfully");
      refetchComments();
      console.log("Step 15: Comments refreshed after deletion");
    },
    onError: (err) => { }
  });

  useEffect(() => {
    console.log("Step 4: Checking for unsaved comments...");
    const unsavedComment = getCommentForPosition(positionId);
    if (unsavedComment) {
      console.log("Step 5: Unsaved comment found, showing AddComment component");
      setShowAddComment(true);
    }
  }, [positionId]);

  const handleAddComments = () => {
    console.log("Step 6: Add Comment button clicked");
    setShowAddComment(true);
    setObj(null);
  };

  const handleEditComment = (comment) => {
    console.log("Step 7: Editing comment:", comment);
    setShowAddComment(true);
    setObj(comment);
    // handleAddComment(positionId, comment);    // Remains open in edit mode even no change is made
  };

  const deleteComment = () => {
    if (selectedComment) {
      updateCandidateCommentMutation.mutate({
        commentId: selectedComment.commentId,
        empId: selectedComment.employeeId,
      });
      console.log("Step 13: Deleting comment:", selectedComment);
      setObj(null);
      handleAddComment(positionId, "");
      setShowDeleteModal(false);
    }
  };

  const addCommentsForPositionMutation = useMutation(addCommentsForPosition, {
    onMutate: () => {
      console.log("Step 8: Mutation started for adding comment...");
    },
    onSuccess: (res) => {
      if (res.responseStatus.statusCode === 1) {
        console.log("Step 9: Trying to add Comment:", res.responseData);
        notifySuccess("Comment added successfully!");
        refetchComments();  // Refresh the comments list
        console.log("Step 10: Comments Added Successfully & Refetched Done");
        setShowAddComment(false);
      } else {
        notifyError(res.responseData.message);
      }
    },
    onError: (err) => {
      console.error("Error adding comment:", err)
      notifyError("Something went wrong");
    }
  });
  
  const handlePredefinedCommentClick = (commentText) => {
    console.log("Step 11: Adding predefined comment:", commentText);
    addCommentsForPositionMutation.mutate({
      comment: commentText,
      candidateId: candidateId,
      employeeId: employeeId,
      id: positionId
    });
  };

  const cancelDelete = () => {
    console.log("Cancel button clicked, closing delete modal");
    setShowDeleteModal(false);
    setSelectedComment(null);
  };

  const handleDeleteComment = (comment) => {
    setShowDeleteModal(true);
    setSelectedComment(comment);
    console.log("Step 12: Delete button clicked for comment:", comment);
  };

  return (
    <div>
      <DeleteConfirmationModel
        showDeleteModal={showDeleteModal}
        deleteComment={deleteComment}
        cancelDelete={cancelDelete}
      />
      <div style={{ display: "flex", alignItems: 'flex-start', gap: "1rem"}}>
        <div style={{width: "77%"}}>
          <div className={style.addButton}>
            {!showAddComment && (
              <button className={style.add_btn} onClick={handleAddComments}>
                Add Comment
              </button>
            )}
          </div>
          {showAddComment && <div style={{ width: "100%"}}>
            <AddComment
              refetchComments={refetchComments}
              candidateId={candidateId}
              showAddComment={showAddComment}
              setShowAddComment={setShowAddComment}
              obj={obj}
              setObj={setObj}
              setShowCommentModal={setShowCommentModal}
              positionId={positionId}
              closeModal={closeModal}
              handleAddComment={handleAddComment}
              getCommentForPosition={getCommentForPosition}
              addCommentsForPositionMutation={addCommentsForPositionMutation}
            />
          </div>}
          <div className={style.description_box1} style={{marginTop: "0.5rem"}}>
            <div style={{paddingBottom: "0.3rem"}}>
              <div className={style.span2}>
                {positionName === undefined && myClientName === undefined ? (
                  <p></p>
                ) : positionName && myClientName ? (
                  <p>{positionCodee} - {positionName} ({myClientName})</p>
                ) : positionCodee ? (
                  <p>{positionCodee} - {positionName}</p>
                ) : (<p>{positionName}</p>)}
              </div>
              <div className={style.maindivContent}></div>
              <div
                className={style.original_table}
                style={{
                  backgroundColor: "white",
                  minHeight: "3rem",
                  marginTop: "7px",
                }}
              >
                <>
                  <Table borderless size="sm" style={{ fontSize: "14.5px" }}>
                    <thead
                      style={{
                        backgroundColor: "#F0F0F0",
                        position: "sticky",
                        top: "-5px",
                      }}
                    >
                      <tr>
                        <th style={{ padding: "10px", width: "3.5rem" }}>Date</th>
                        <th style={{ padding: "10px", minWidth: "100px" }}>Added By</th>
                        <th style={{ padding: "10px" }}>Comment</th>
                        <th style={{ padding: "10px", minWidth: "75px" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!commentLoading && CommentData?.length > 0 ? (
                        CommentData.map((comment, index) => (
                          <tr key={index}>
                            <td style={{ paddingLeft: "10px", minWidth: "4rem" }}>
                              {comment?.created
                                ? moment(comment.created).year() === moment().year()
                                  ? moment(comment.created).format("DD MMM")
                                  : moment(comment.created).format("DD MMM YYYY")
                                : null}
                            </td>
                            <td style={{ paddingLeft: "10px", minWidth: "170px", width: "170px" }}>
                              {comment?.employeeName}
                            </td>
                            <td style={{ paddingLeft: "10px", whiteSpace: "pre-wrap", overflowWrap: "anywhere" }}>
                              {comment?.comment}
                            </td>
                            {comment.employeeId === employeeId ? (
                              <td style={{ paddingLeft: "10px", minWidth: "75px" }}>
                                <img
                                  src={editImg}
                                  height="20px"
                                  width="20px"
                                  alt="edit"
                                  style={{ cursor: "pointer" }}
                                  draggable="false"
                                  title="Edit Comment"
                                  onClick={() => handleEditComment(comment)}
                                  onKeyDown={() => handleEditComment(comment)}
                                  tabIndex={0}
                                />
                                <img
                                  src={deleteImg}
                                  alt="delete"
                                  height="22px"
                                  width="35px"
                                  draggable="false"
                                  style={{ cursor: "pointer", paddingLeft: "10px" }}
                                  title="Delete Comment"
                                  onClick={() => handleDeleteComment(comment)}
                                  onKeyDown={() => handleDeleteComment(comment)}
                                  tabIndex={0}
                                />
                              </td>
                            ) : (
                              <td style={{ paddingLeft: "10px", minWidth: "75px" }}></td>
                            )}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4" style={{ padding: "10px", textAlign: "center" }}>
                            No Comments Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {!commentLoading && CommentData?.length === 0 && (
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: "14px",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      No Comments Found
                    </p>
                  )}
                </>
              </div>
              {/* <div className={style.addButton}>
                {!showAddComment && (
                  <button className={style.add_btn} onClick={handleAddComments}>
                    Add Comment
                  </button>
                )}
              </div> */}
            </div>
          </div>
          {/* {showAddComment && <div style={{ width: "100%"}}>
            <AddComment
              refetchComments={refetchComments}
              candidateId={candidateId}
              showAddComment={showAddComment}
              setShowAddComment={setShowAddComment}
              obj={obj}
              setObj={setObj}
              setShowCommentModal={setShowCommentModal}
              positionId={positionId}
              closeModal={closeModal}
              handleAddComment={handleAddComment}
              getCommentForPosition={getCommentForPosition}
            />
          </div>} */}
        </div>
        <div style={{width: "23%", overflowY: "auto", display: "flex", flexWrap: 'wrap', gap: "0.5rem", position: "sticky", top: "0", }}>
          {preDefinedCommentLoading ? (<div>Loading...</div>) : (
            preDefinedComment && preDefinedComment.map((comment, index) => (<div key={index} style={{ flexBasis: "100%", display: "flex", justifyContent: "start" }}>
              <ATSButton onClick={() => handlePredefinedCommentClick(comment)}
                styles={{backgroundColor:"white", color:"black", fontSize:"13px", borderRadius:"20px", textAlign:"start", border:"2px solid #65a15e"}}>{comment}<br/>
              </ATSButton>
            </div>))
          )}
        </div>
      </div>
    </div>
  );
}

const DeleteConfirmationModel = ({
  showDeleteModal,
  cancelDelete,
  deleteComment,
}) => {
  return (
    <Modal
      open={showDeleteModal}
      onClose={() => {
        showDeleteModal(false);
      }}
      showCloseIcon={false}
      center
    >
      <div className={style.deleteModal}>
        <div> Do you want to delete this comment ?</div>
        <div className={style.btnRow}>
          <button className={style.yes_btn} onClick={deleteComment}>
            Yes
          </button>
          <button className={style.no_btn} onClick={cancelDelete}>
            No
          </button>
        </div>
      </div>
    </Modal>
  );
};

const DeleteConfirmationModelTag = ({
  showDeleteModal,
  cancelDelete,
  deleteComment,
}) => {
  return (
    <Modal
      open={showDeleteModal}
      onClose={() => {
        showDeleteModal(false);
      }}
      showCloseIcon={false}
      center
    >
      <div className={style.deleteModal}>
        <div> Do you want to delete this tag ?</div>
        <div className={style.btnRow}>
          <button className={style.yes_btn} onClick={deleteComment}>
            Yes
          </button>
          <button className={style.no_btn} onClick={cancelDelete}>
            No
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CommentHistory;
