import React, { useState, useEffect } from "react";
import style from "./AddComment.module.css";
import { employeeId } from "../../../../../Utils/constants";

function AddComment({
  refetchComments,
  candidateId,
  setShowAddComment,
  obj,
  setObj,
  setShowCommentModal,
  positionId,
  closeModal,
  handleAddComment,
  getCommentForPosition,
  addCommentsForCandidateMutation,
}) {
  console.log("Step 16: Rendering AddComment for candidateId:", candidateId);
  const [commentData, setCommentData] = useState({
    comment: obj ? obj.comment : getCommentForPosition(candidateId)?.comment,
    commentId: obj ? obj.commentId : getCommentForPosition(candidateId)?.commentId,
    id: candidateId,
    employeeId: employeeId,
  });

  useEffect(() => {
    console.log("Step 18: Updating comment data from obj:", obj);
    setCommentData(prev => ({
      ...prev,
      comment: obj ? obj.comment : getCommentForPosition(candidateId)?.comment || prev?.comment ||"",
      commentId: obj ? obj.commentId : getCommentForPosition(candidateId)?.commentId || prev?.commentId || "",
    }));
  }, [obj, candidateId, getCommentForPosition]);
  
  // const addCommentsForCandidateMutation = useMutation(addCommentsForCandidate, {
  //   onMutate: () => { console.log("Step 19: Mutation started: Adding comment")},
  //   onSuccess: (res) => {
  //     if (res.responseStatus.statusCode === 1) {
  //       console.log("Step 20: Comment added successfully:", res);
  //       notifySuccess(res.responseData);
  //       console.log("Step 21: Refetching comments");
  //       refetchComments();
  //       console.log("Step 22: Comments refreshed");
  //       // setShowAddComment(false);
  //     }
  //     else {
  //       notifyError(res.responseData.message);
  //     }
  //   },
  //   onError: (err) => {
  //     notifyError("Something went wrong");
  //     console.error("Error adding comment:", err)
  //   },
  // });
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Step 23: Submitting comment:", commentData);
    if (commentData.comment.trim()) {
      addCommentsForCandidateMutation.mutate({
        comment: commentData?.comment,
        id: candidateId,
        employeeId: employeeId,
        commentId: commentData?.commentId ? commentData.commentId : obj?.commentId,
      });
      
      // closeModal();
      // setShowCommentModal(false);
      handleAddComment(candidateId, "");
    }
  };
  const handleCancel = (e) => {
    e.preventDefault();
    console.log("Cancel button clicked, resetting comment data");
    setCommentData((prevData) => ({
      ...prevData,
      comment: "",
      commentId: null,
    }));
    setObj(null);
    setShowAddComment(false);
    handleAddComment(candidateId, "");
  };
  const handleChange = (e) => {
    const updatedComment = e.target.value.replace(/^\s+/, '');
    console.log("Step 24: Comment text changed:", updatedComment);
    setCommentData((prev) => {
      const newCommentData = { ...prev, comment: updatedComment };
      handleAddComment(candidateId, {commentId: prev.commentId || obj?.commentId, comment: updatedComment});
      return newCommentData;
    });
  };

  useEffect(() => {
    if (addCommentsForCandidateMutation.isSuccess) {
      refetchComments();
      console.log("Step 25: Refetched comments after successful mutation...");
    }
  }, [addCommentsForCandidateMutation.isSuccess]);

  return (
    <div className={style.main_container}>
      <p className={style.heading}>{obj ? "Edit Comment" : "Add Comment"}</p>

      <form>
        <textarea
          name="description"
          id="com"
          cols="50"
          rows="10"
          className={style.textArea}
          value={commentData?.comment}
          onChange={handleChange}
        ></textarea>
        <br />
        <div className={style.btn_container}>
          <button type="cancel" className={style.btn} onClick={handleCancel}>
            Cancel
          </button>
          <button type="submit" className={style.btn} onClick={handleSubmit}>
            {obj ? "Update" : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddComment;