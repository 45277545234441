import { useLocation, useHistory } from "react-router-dom";
import SideBar from "../../sidebar/sidebar";
import style from "./projectCostingFixBid.module.css";
import { path } from "../../../../../routes/PathNames";
import { useQuery } from "react-query";
import { getMetricsByProjectId } from "../../../../../Utils/axios/pcm_Api";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import * as Img from "../../../../../assets/index";
import React from "react";
import { useState } from "react";
import calendarIcon from "../../../../../assets/calendarIcon.png";
import Loading from "../../loading/loading";
import { notifyError } from "../../../../atsDashboard/utils/notify";
import { addCommas, addCommasPCM, addCommasWithDecimal } from "../../../../Appraisal_System/commonComponent/utils/AddCommas";
import {
  localStorageGetItem,
  localStorageSetItem,
  projectCostingAndMarginAccessCode,
} from "../../../../../Utils/constants";
import useValidationAccess from "../../../../custumHooks/useValidationAccess";

export default function ProjectCostingFixBid() {
  return (
    <div style={{ display: "flex" }}>
      <SideBar />
      <Body />
    </div>
  );
}

function Body() {
  const location = useLocation();
  const history = useHistory();
  const id = location?.state?.id;
  const projectName = location?.state?.projectName;
  const [showInDt, setShowInDt] = useState(false);
  const [filter, setFilter] = useState({
    date:
      localStorageGetItem("pcm_dt") || moment().format("YYYY-MM-DDTHH:mm:ss"),
  });
  const { checkValidateAccess } = useValidationAccess();

  const { data, isLoading } = useQuery(
    ["findProjectMetricsbyId", id, filter?.date],
    () => {
      return getMetricsByProjectId({
        projectId: id,
        date:
          moment(filter?.date)
            .format("YYYY-MM-DDTHH:mm:ss")
            .toString()
            .split("T")[0] + "T00:00:00",
        isSoftAllocated: false,
        billingModelId: [1]
      });
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (err) => notifyError("Something went wrong!"),
    }
  );

  function roundToZeroDecimalPlaces(number) {
    if (
      number === null ||
      number === undefined ||
      number === 0 ||
      number === "0" ||
      typeof number === "string"
    )
      return null;
    return addCommasPCM(number.toFixed(0));
  }

  const handleRedirectProjectsList = () => {
    setTimeout(() => {
      history.push(path.pcm_dashboard_fixedBidProjects, { id, projectName });
    }, 0);
  };

  const handleRedirectProjectCostBreakup = () => {
    setTimeout(() => {
      history.push(path.pcm_projectCostBreakUpFixBid, { id, projectName });
    }, 0);
  };

  return (
    <div className={style.main}>
      <div className={style.heading}>
        <div className={style.header}>
          <span
            className={style.breadCrumb}
            onClick={handleRedirectProjectsList}
          >
            Fix Bid Projects
          </span>
          <span style={{ color: "#954b00" }}>&nbsp;&gt;&nbsp;</span>{" "}
          <span className={`${style.breadCrumb2}`}>{projectName}</span>
        </div>
        <div className={style.headerDate}>
          {showInDt ? (
            <div className={style.csd}>
              <DatePicker
                dateFormat="DD MMM YY"
                startDate={null}
                value={moment(filter.date).format("DD MMM YY")}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onChange={(date) => {
                  setFilter((prev) => ({ ...prev, date }));
                  setShowInDt(!showInDt);
                  localStorageSetItem("pcm_dt", date);
                }}
                defaultValue={null}
                customInput={<CustomInputClg />}
                style={{ backgroundColor: "#F5F6FA" }}
              />
            </div>
          ) : (
            <div className={style.headerDt}>
              {moment(filter?.date).format("DD MMM YY")}
              <span>
                <img
                  src={Img.cross}
                  width="12px"
                  height="12px"
                  alt="Cross"
                  style={{
                    cursor: "pointer",
                    marginLeft: "10px",
                    marginBottom: "4px",
                  }}
                  onClick={() => {
                    setShowInDt(!showInDt);
                  }}
                />
              </span>
            </div>
          )}
        </div>
      </div>
      <div>
        {isLoading ? (
          <Loading />
        ) : data?.message ? (
          <div className={style.noProjectData}>{data?.message}</div>
        ) : (
          <div className={style.tableContainer}>
            <table className={style.table}>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={4} style={{ padding: "0" }}>
                    <div className={style.insidetableContainer}>
                      <table className={style.insidetable}>
                        <thead>
                          <tr
                            style={{
                              backgroundColor: "#b35e09",
                              color: "white",
                            }}
                          >
                            <th style={{ padding: "10px" }}>
                              Project SOW Value
                            </th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className={style.tSubHead} colSpan={1}>
                              Project SOW Value
                            </td>
                            <td className={style.tSubValue} colSpan={3}>
                              {data?.projectSOWValue
                                ? roundToZeroDecimalPlaces(data?.projectSOWValue)
                                : ""}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={4} style={{ padding: "0" }}>
                    <div className={style.insidetableContainer}>
                      <table className={style.insidetable}>
                        <thead>
                          <tr
                            style={{
                              backgroundColor: "#b35e09",
                              color: "white",
                            }}
                          >
                            <th
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                width: "25%",
                                padding: "10px",
                              }}
                            >
                              Actual Project Cost
                            </th>
                            <th
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                width: "25%",
                                padding: "10px",
                              }}
                            >
                              Without Overhead
                            </th>
                            <th
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                width: "25%",
                                padding: "10px",
                              }}
                            >
                              With Overhead
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className={style.tSubHead2}>Project Cost</td>

                            <td className={style.tSubValue2}>
                              {data?.projectCostWithoutOverhead ||
                              data?.additionalCostOfAProject
                                ? roundToZeroDecimalPlaces(
                                    (data?.projectCostWithoutOverhead
                                      ? data?.projectCostWithoutOverhead
                                      : 0) +
                                      (data?.additionalCostOfAProject
                                        ? data?.additionalCostOfAProject
                                        : 0)
                                  )
                                : ""}
                            </td>
                            <td className={style.tSubValue2}>
                              {data?.projectCostWithOverhead ||
                              data?.additionalCostOfAProject
                                ? roundToZeroDecimalPlaces(
                                    (data?.projectCostWithOverhead
                                      ? data?.projectCostWithOverhead
                                      : 0) +
                                      (data?.additionalCostOfAProject
                                        ? data?.additionalCostOfAProject
                                        : 0)
                                  )
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td className={style.tSubHead2}>Gross Margin</td>

                            <td className={style.tSubValue2}>
                              {data?.grossMargin
                                ? roundToZeroDecimalPlaces(data?.grossMargin)
                                : ""}
                            </td>
                            <td className={style.tSubValue2}>
                              {<span style={{ color: "#c1c1c1" }}>N/A</span>}
                            </td>
                          </tr>
                          <tr>
                            <td className={style.tSubHead2}>Gross Margin %</td>

                            <td className={style.tSubValue2}>
                              {data?.grossMarginPercent
                                ? roundToZeroDecimalPlaces(
                                    data?.grossMarginPercent
                                  ) !== "0"
                                  ? roundToZeroDecimalPlaces(
                                      data?.grossMarginPercent
                                    ) + "%"
                                  : ""
                                : ""}
                            </td>
                            <td className={style.tSubValue2}>
                              {<span style={{ color: "#c1c1c1" }}>N/A</span>}
                            </td>
                          </tr>
                          <tr>
                            <td className={style.tSubHead2}>Net Margin</td>

                            <td className={style.tSubValue2}>
                              {<span style={{ color: "#c1c1c1" }}>N/A</span>}
                            </td>
                            <td className={style.tSubValue2}>
                              {data?.netMargin
                                ? roundToZeroDecimalPlaces(data?.netMargin)
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td className={style.tSubHead2}>Net Margin %</td>

                            <td className={style.tSubValue2}>
                              {<span style={{ color: "#c1c1c1" }}>N/A</span>}
                            </td>
                            <td className={style.tSubValue2}>
                              {data?.netMarginPercent
                                ? roundToZeroDecimalPlaces(
                                    data?.netMarginPercent
                                  ) !== "0"
                                  ? roundToZeroDecimalPlaces(
                                      data?.netMarginPercent
                                    ) + "%"
                                  : ""
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td className={style.tSubHead3}>
                              Burnt Percentage
                            </td>

                            <td className={style.tSubValue3}>
                              {data?.burntPercentageWithoutOverhead
                                ? roundToZeroDecimalPlaces(
                                    data?.burntPercentageWithoutOverhead
                                  ) !== "0"
                                  ? roundToZeroDecimalPlaces(
                                      data?.netMarginPercent
                                    ) + "%"
                                  : ""
                                : ""}
                            </td>
                            <td className={style.tSubValue3}>
                              {data?.burntPercentageWithOverhead
                                ? roundToZeroDecimalPlaces(
                                    data?.burntPercentageWithOverhead
                                  ) !== "0"
                                  ? roundToZeroDecimalPlaces(
                                      data?.burntPercentageWithOverhead
                                    ) + "%"
                                  : ""
                                : ""}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colSpan={4} style={{ padding: "0" }}>
                    <div className={style.insidetableContainer}>
                      <table className={style.insidetable}>
                        <thead>
                          <tr
                            style={{
                              backgroundColor: "#b35e09",
                              color: "white",
                            }}
                          >
                            <th
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                width: "25%",
                                padding: "10px",
                              }}
                            >
                              Planned Project Cost
                            </th>
                            <th
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                width: "25%",
                                padding: "10px",
                              }}
                            >
                              Without Overhead
                            </th>
                            <th
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                width: "25%",
                                padding: "10px",
                              }}
                            >
                              With Overhead
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className={style.tSubHead2}>Project Cost</td>

                            <td className={style.tSubValue2}>
                              {data?.plannedProjectCost ||
                              data?.additionalCostOfAProject
                                ? roundToZeroDecimalPlaces(
                                    (data?.plannedProjectCost
                                      ? data?.plannedProjectCost
                                      : 0) +
                                      (data?.additionalCostOfAProject
                                        ? data?.additionalCostOfAProject
                                        : 0)
                                  )
                                : ""}
                            </td>
                            <td className={style.tSubValue2}>
                              {data?.plannedProjectCostWithOverhead ||
                              data?.additionalCostOfAProject
                                ? roundToZeroDecimalPlaces(
                                    (data?.plannedProjectCostWithOverhead
                                      ? data?.plannedProjectCostWithOverhead
                                      : 0) +
                                      (data?.additionalCostOfAProject
                                        ? data?.additionalCostOfAProject
                                        : 0)
                                  )
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td className={style.tSubHead2}>
                              Estimated Gross Margin
                            </td>

                            <td className={style.tSubValue2}>
                              {data?.estimatedGrossMargin
                                ? roundToZeroDecimalPlaces(
                                    data?.estimatedGrossMargin
                                  )
                                : ""}
                            </td>
                            <td className={style.tSubValue2}>
                              {<span style={{ color: "#c1c1c1" }}>N/A</span>}
                            </td>
                          </tr>
                          <tr>
                            <td className={style.tSubHead2}>
                              Estimated Gross Margin %
                            </td>

                            <td className={style.tSubValue2}>
                              {data?.estimatedGrossMarginPercent
                                ? roundToZeroDecimalPlaces(
                                    data?.estimatedGrossMarginPercent
                                  ) !== "0"
                                  ? roundToZeroDecimalPlaces(
                                      data?.estimatedGrossMarginPercent
                                    ) + "%"
                                  : ""
                                : ""}
                            </td>
                            <td className={style.tSubValue2}>
                              {<span style={{ color: "#c1c1c1" }}>N/A</span>}
                            </td>
                          </tr>
                          <tr>
                            <td className={style.tSubHead2}>
                              Estimated Net Margin
                            </td>

                            <td className={style.tSubValue2}>
                              {<span style={{ color: "#c1c1c1" }}>N/A</span>}
                            </td>
                            <td className={style.tSubValue2}>
                              {data?.estimatedNetMargin ? (
                                roundToZeroDecimalPlaces(
                                  data?.estimatedNetMargin
                                )
                              ) : (
                                <span style={{ color: "#c1c1c1" }}>N/A</span>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className={style.tSubHead3}>
                              Estimated Net Margin %
                            </td>

                            <td className={style.tSubValue3}>
                              {<span style={{ color: "#c1c1c1" }}>N/A</span>}
                            </td>
                            <td className={style.tSubValue3}>
                              {data?.estimatedNetMarginPercent ? (
                                roundToZeroDecimalPlaces(
                                  data?.estimatedNetMarginPercent
                                ) !== "0" ? (
                                  roundToZeroDecimalPlaces(
                                    data?.estimatedNetMarginPercent
                                  ) + "%"
                                ) : (
                                  ""
                                )
                              ) : (
                                <span style={{ color: "#c1c1c1" }}>N/A</span>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colSpan={4} style={{ padding: "0" }}>
                    <div className={style.insidetableContainer}>
                      <table className={style.insidetable}>
                        <thead>
                          <tr
                            style={{
                              backgroundColor: "#b35e09",
                              color: "white",
                            }}
                          >
                            <th
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                width: "25%",
                                padding: "10px",
                              }}
                            >
                              Invoices - Metrics
                            </th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colSpan={1} className={style.tSubHead2}>
                              Total value of invoices Raised
                            </td>
                            <td colSpan={2} className={style.tSubValue2}>
                              {data?.totalInvoicesRaised
                                ? roundToZeroDecimalPlaces(
                                    data?.totalInvoicesRaised
                                  )
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={1} className={style.tSubHead2}>
                              Invoices Pending
                            </td>
                            <td colSpan={2} className={style.tSubValue2}>
                              {data?.invoicesPending
                                ? roundToZeroDecimalPlaces(data?.invoicesPending)
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td className={style.tSubHead2}>
                              Invoices Raised % (of Project Cost)
                            </td>
                            <td className={style.tSubValue2}>
                              {data?.totalInvoicesRaisedPercentByProjectCostWithoutOverhead
                                ? roundToZeroDecimalPlaces(
                                    data?.totalInvoicesRaisedPercentByProjectCostWithoutOverhead
                                  ) !== "0"
                                  ? roundToZeroDecimalPlaces(
                                      data?.totalInvoicesRaisedPercentByProjectCostWithoutOverhead
                                    ) + "%"
                                  : ""
                                : ""}
                            </td>
                            <td className={style.tSubValue2}>
                              {data?.totalInvoicesRaisedPercentByProjectCostWithOverhead
                                ? roundToZeroDecimalPlaces(
                                    data?.totalInvoicesRaisedPercentByProjectCostWithOverhead
                                  ) !== "0"
                                  ? roundToZeroDecimalPlaces(
                                      data?.totalInvoicesRaisedPercentByProjectCostWithOverhead
                                    ) + "%"
                                  : ""
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={1} className={style.tSubHead3}>
                              Invoices Raised % (of Project SOW Value)
                            </td>
                            <td colSpan={2} className={style.tSubValue3}>
                              {data?.totalInvoicesRaisedPercentBySOWValue
                                ? roundToZeroDecimalPlaces(
                                    data?.totalInvoicesRaisedPercentBySOWValue
                                  ) !== "0"
                                  ? roundToZeroDecimalPlaces(
                                      data?.totalInvoicesRaisedPercentBySOWValue
                                    ) + "%"
                                  : ""
                                : ""}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colSpan={4} style={{ padding: "0" }}>
                    <div className={style.insidetableContainer}>
                      <table className={style.insidetable}>
                        <thead>
                          <tr
                            style={{
                              backgroundColor: "#b35e09",
                              color: "white",
                            }}
                          >
                            <th
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                width: "25%",
                                padding: "10px",
                              }}
                            >
                              Money Received - Metrics
                            </th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colSpan={1} className={style.tSubHead2}>
                              Money Received
                            </td>
                            <td colSpan={2} className={style.tSubValue2}>
                              {data?.totalMoneyReceived
                                ? roundToZeroDecimalPlaces(
                                    data?.totalMoneyReceived
                                  )
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={1} className={style.tSubHead2}>
                              Money Pending
                            </td>
                            <td colSpan={2} className={style.tSubValue2}>
                              {data?.moneyPending
                                ? roundToZeroDecimalPlaces(data?.moneyPending)
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td className={style.tSubHead2}>
                              Money Received % (of Project Cost)
                            </td>
                            <td className={style.tSubValue2}>
                              {data?.totalMoneyReceivedPercentByProjectCostWithoutOverhead
                                ? roundToZeroDecimalPlaces(
                                    data?.totalMoneyReceivedPercentByProjectCostWithoutOverhead
                                  ) !== "0"
                                  ? roundToZeroDecimalPlaces(
                                      data?.totalMoneyReceivedPercentByProjectCostWithoutOverhead
                                    ) + "%"
                                  : ""
                                : ""}
                            </td>
                            <td className={style.tSubValue2}>
                              {data?.totalMoneyReceivedPercentByProjectCostWithOverhead
                                ? roundToZeroDecimalPlaces(
                                    data?.totalMoneyReceivedPercentByProjectCostWithOverhead
                                  ) !== "0"
                                  ? roundToZeroDecimalPlaces(
                                      data?.totalMoneyReceivedPercentByProjectCostWithOverhead
                                    ) + "%"
                                  : ""
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={1} className={style.tSubHead3}>
                              Money Received % (of Project SOW Value)
                            </td>
                            <td colSpan={2} className={style.tSubValue3}>
                              {data?.totalMoneyReceivedPercentBySOWValue
                                ? roundToZeroDecimalPlaces(
                                    data?.totalMoneyReceivedPercentBySOWValue
                                  ) !== "0"
                                  ? roundToZeroDecimalPlaces(
                                      data?.totalMoneyReceivedPercentBySOWValue
                                    ) + "%"
                                  : ""
                                : ""}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        {checkValidateAccess(
          projectCostingAndMarginAccessCode?.project_cost_breakup
        ) && (
          <div className={style.btnRow}>
            <div>
              <button
                className={style.btnCancel}
                onClick={handleRedirectProjectsList}
              >
                Back
              </button>
              {!data?.message && (
                <button
                  className={style.btnSubmit}
                  onClick={handleRedirectProjectCostBreakup}
                >
                  View Project Cost-Breakup
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const CustomInputClg = React.forwardRef((props, ref) => {
  return (
    <div onClick={props.onClick}>
      <label ref={ref} style={{ padding: "8px 8px 8px 5px", fontWeight: 600 }}>
        {props.value || props.placeholder}
      </label>
      <img
        type="image/svg+xml"
        width="20px"
        style={{ position: "absolute", right: "3%", top: "19%", zIndex: "0" }}
        src={calendarIcon}
        onClick={props.onClick}
        alt=""
      />
    </div>
  );
});
