import SideBar from "../../sidebar/sidebar";
import style from "./projectCostBreakupFixBid.module.css";
import { useLocation, useHistory } from "react-router-dom";
import dropdownIcon from "../../../../../assets/arrow-down.png";
import upIcon from "../../../../../assets/arrow-up.png";
import { createContext, useContext, useEffect, useState } from "react";
import { path } from "../../../../../routes/PathNames";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import React from "react";
import calendarIcon from "../../../../../assets/calendarIcon.png";
import * as Img from "../../../../../assets/index";
import { useQuery } from "react-query";
import {
  getACBDesignations,
  getActualProjectCostbreakup,
  getPlannedCostByProjectId,
  getPlannedCostByProjectIdAndDesignation,
} from "../../../../../Utils/axios/pcm_Api";
import { notifyError } from "../../../../atsDashboard/utils/notify";
import { addCommasPCM, addCommasWithDecimal } from "../../../../Appraisal_System/commonComponent/utils/AddCommas";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import {
  localStorageGetItem,
  localStorageSetItem,
  projectCostingAndMarginAccessCode,
} from "../../../../../Utils/constants";
import useValidationAccess from "../../../../custumHooks/useValidationAccess";

export default function ProjectCostBreakUpFixBid() {
  const { checkValidateAccess } = useValidationAccess();
  return (
    <div style={{ display: "flex", width: "100%" }}>
      {checkValidateAccess(
        projectCostingAndMarginAccessCode?.project_cost_breakup
      ) && (
        <>
          <SideBar />
          <Body />
        </>
      )}
    </div>
  );
}

function Body() {
  const [aCostBreakup, setACostBreakup] = useState({
    loading: true,
    data: null,
    acb: true,
    acbDesignation: null,
  });
  const [pCostBreakup, setPCostBreakup] = useState({
    loading: true,
    data: null,
    ppc: true,
    ppcDesignation: null,
  });
  const location = useLocation();
  const history = useHistory();
  const id = location?.state?.id;
  const projectName = location?.state?.projectName;
  const [filter, setFilter] = useState({
    date:
      localStorageGetItem("pcm_dt") || moment().format("YYYY-MM-DDTHH:mm:ss"),
  });
  const [showInDt, setShowInDt] = useState(false);
  const { checkValidateAccess } = useValidationAccess();

  const { data, isLoading } = useQuery(
    ["getActualProjectCostbreakup", id, filter?.date],
    () =>
      getActualProjectCostbreakup({
        projectId: id,
        date:
          moment(filter?.date)
            .format("YYYY-MM-DDTHH:mm:ss")
            .toString()
            .split("T")[0] + "T00:00:00",
        isSoftAllocated: false,
        billingModelId: [1]
      }),
    {
      onError: (err) => {
        notifyError("Something went wrong!");
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
  const { data: acbDesignation, isLoading: acbDesigLoading } = useQuery(
    ["getActualProjectCostbreakupDesignation", id, filter?.date],
    () =>
      getACBDesignations({
        projectId: id,
        date:
          moment(filter?.date)
            .format("YYYY-MM-DDTHH:mm:ss")
            .toString()
            .split("T")[0] + "T00:00:00",
        isSoftAllocated: false,
        billingModelId: [1]
      }),
    {
      onError: (err) => {
        notifyError("Something went wrong!");
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: ppCostBreakupDesig, isLoading: ppCostBreakupDesigLoading } =
    useQuery(
      ["getPlannedProjectCostbreakupDesignation", id, filter?.date],
      () =>
        getPlannedCostByProjectIdAndDesignation({
          projectId: id,
          date:
            moment(filter?.date)
              .format("YYYY-MM-DDTHH:mm:ss")
              .toString()
              .split("T")[0] + "T00:00:00",
          isSoftAllocated: false,
          billingModelId: [1]
        }),
      {
        onError: (err) => {
          notifyError("Something went wrong!");
        },
        retry: false,
        refetchOnWindowFocus: false,
      }
    );

  const { data: ppCostBreakUp, isLoading: ppCostBreakUpLoading } = useQuery(
    ["getPlannedProjectCostbreakup", id, filter?.date],
    () =>
      getPlannedCostByProjectId({
        projectId: id,
        date:
          moment(filter?.date)
            .format("YYYY-MM-DDTHH:mm:ss")
            .toString()
            .split("T")[0] + "T00:00:00",
        isSoftAllocated: false,
        billingModelId: [1]
      }),
    {
      onError: (err) => {
        notifyError("Something went wrong!");
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (!isLoading) {
      setACostBreakup({
        loading: false,
        data: data,
        acb: true,
        acbDesignation: acbDesignation,
      });
    } else {
      setACostBreakup({
        loading: true,
        data: null,
        acb: true,
        acbDesignation: null,
      });
    }
  }, [data, isLoading, acbDesigLoading]);

  useEffect(() => {
    if (!ppCostBreakUpLoading) {
      setPCostBreakup({
        loading: false,
        data: ppCostBreakUp,
        ppc: true,
        ppcDesignation: ppCostBreakupDesig,
      });
    } else {
      setPCostBreakup({
        loading: true,
        data: null,
        ppc: true,
        ppcDesignation: null,
      });
    }
  }, [ppCostBreakUp, ppCostBreakUpLoading, ppCostBreakupDesigLoading]);

  const handleRedirectProjectCosting = () => {
    setTimeout(() => {
      history.push(path.pcm_projectCostingInformationFixBid, { id, projectName });
    }, 0);
  };

  const handleRedirectProjectsList = () => {
    setTimeout(() => {
      history.push(path.pcm_dashboard_fixedBidProjects, { id });
    }, 0);
  };

  return (
    <div className={style.main}>
      <div className={style.heading}>
        <div className={style.header}>
          <span
            className={style.breadCrumb}
            onClick={handleRedirectProjectsList}
          >
            Fix Bid Projects&nbsp;
          </span>{" "}
          <span style={{ color: "#954b00" }}> &gt;</span> &nbsp;{" "}
          <span
            className={style.breadCrumb}
            onClick={handleRedirectProjectCosting}
          >
            {projectName}{" "}
          </span>{" "}
          <span style={{ color: "#954b00" }}>&nbsp;&gt;&nbsp;</span>{" "}
          <span className={`${style.breadCrumb2}`}>Project Cost Breakup</span>
        </div>
        <div className={style.headerDate}>
          {showInDt ? (
            <div className={style.csd}>
              <DatePicker
                dateFormat="DD MMM YY"
                startDate={null}
                value={moment(filter.date).format("DD MMM YY")}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onChange={(date) => {
                  setFilter((prev) => ({ ...prev, date }));
                  setShowInDt(!showInDt);
                  localStorageSetItem("pcm_dt", date);
                }}
                defaultValue={null}
                customInput={<CustomInputClg />}
                style={{ backgroundColor: "#F5F6FA" }}
              />
            </div>
          ) : (
            <div className={style.headerDt}>
              {moment(filter?.date).format("DD MMM YY")}
              <span>
                <img
                  src={Img.cross}
                  width="12px"
                  height="12px"
                  alt="Cross"
                  style={{
                    cursor: "pointer",
                    marginLeft: "10px",
                    marginBottom: "4px",
                  }}
                  onClick={() => {
                    setShowInDt(!showInDt);
                  }}
                />
              </span>
            </div>
          )}
        </div>
      </div>
      <div>
        {checkValidateAccess(
          projectCostingAndMarginAccessCode?.actual_project_cost
        ) && (
          <div>
            <Collapsible header={"Actual Project Cost"} {...aCostBreakup} />
          </div>
        )}
        {checkValidateAccess(
          projectCostingAndMarginAccessCode?.planned_project_cost
        ) && (
          <div>
            <Collapsible header={"Planned Project Cost"} {...pCostBreakup} />
          </div>
        )}
      </div>
      <div className={style.btnRow}>
        <div>
          <button
            className={style.btnSubmit}
            onClick={handleRedirectProjectCosting}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

function Collapsible(props) {
  const [open, setOpen] = useState(false);

  return (
    <div className={style.collapsible}>
      <div
        className={open ? style.collapsibleHeader1 : style.collapsibleHeader}
      >
        <div className={style.collapsibleHeading}>{props?.header}</div>
        <div>
          {open ? (
            <div className={style.down_collapase}>
              <IoIosArrowUp
                className={style.icon}
                onClick={() => setOpen(false)}
              />
            </div>
          ) : (
            <div className={style.down_collapase}>
              <IoIosArrowDown
                className={style.icon}
                onClick={() => setOpen(true)}
              />
            </div>
          )}
        </div>
      </div>
      {open && (
        <div className={style.collapsibleBody1}>
          <CollapsibleBody {...props} />
        </div>
      )}
    </div>
  );
}

function CollapsibleBody(props) {
  return (
    <div>
      <BreakUpSubFunction {...props} />
      <BreakUpResource {...props} />
    </div>
  );
}

function BreakUpSubFunction({ header, acbDesignation, ppcDesignation }) {
  let totalWithOverhead = 0,
    totalWithoutOverhead = 0;
  let tableBody = [];

  if (acbDesignation?.length) {
    tableBody = acbDesignation?.map((item, index) => {
      totalWithOverhead += item?.costWithOverheadByDesignation || 0;
      totalWithoutOverhead += item?.costWithoutOverheadByDesignation || 0;
      return (
        <tr key={index} className={style.row}>
          {index === acbDesignation.length - 1 ? (
            <>
              <td className={style.lastHeadResource}>
                {item?.designationName}
              </td>
              <td className={style.lastValue}>
                {item?.costWithOverheadByDesignation
                  ? roundToZeroDecimalPlaces(item?.costWithOverheadByDesignation)
                  : 0}
              </td>
              <td className={style.lastValue}>
                {item?.costWithoutOverheadByDesignation
                  ? roundToZeroDecimalPlaces(
                      item?.costWithoutOverheadByDesignation
                    )
                  : 0}
              </td>
            </>
          ) : (
            <>
              <td className={style.subHeadResource}>{item?.designationName}</td>
              <td className={style.subValue}>
                {item?.costWithOverheadByDesignation
                  ? roundToZeroDecimalPlaces(item?.costWithOverheadByDesignation)
                  : 0}
              </td>
              <td className={style.subValue}>
                {item?.costWithoutOverheadByDesignation
                  ? roundToZeroDecimalPlaces(
                      item?.costWithoutOverheadByDesignation
                    )
                  : 0}
              </td>
            </>
          )}
        </tr>
      );
    });
  } else if (ppcDesignation?.length) {
    tableBody = ppcDesignation?.map((item, index) => {
      totalWithOverhead += item?.costWithOverheadByDesignation || 0;
      totalWithoutOverhead += item?.costWithoutOverheadByDesignation || 0;
      return (
        <tr key={index} className={style.row}>
          {index === ppcDesignation.length - 1 ? (
            <>
              <td className={style.lastHeadResource}>
                {item?.designationName}
              </td>
              <td className={style.lastValue}>
                {item?.costWithOverheadByDesignation
                  ? roundToZeroDecimalPlaces(item?.costWithOverheadByDesignation)
                  : 0}
              </td>
              <td className={style.lastValue}>
                {item?.costWithoutOverheadByDesignation
                  ? roundToZeroDecimalPlaces(
                      item?.costWithoutOverheadByDesignation
                    )
                  : 0}
              </td>
            </>
          ) : (
            <>
              <td className={style.subHeadResource}>{item?.designationName}</td>
              <td className={style.subValue}>
                {item?.costWithOverheadByDesignation
                  ? roundToZeroDecimalPlaces(item?.costWithOverheadByDesignation)
                  : 0}
              </td>
              <td className={style.subValue}>
                {item?.costWithoutOverheadByDesignation
                  ? roundToZeroDecimalPlaces(
                      item?.costWithoutOverheadByDesignation
                    )
                  : 0}
              </td>
            </>
          )}
        </tr>
      );
    });
  } else {
    tableBody = (
      <tr className={style.tRow}>
        <td colSpan={10} style={{ textAlign: "center", padding: "10px" }}>
          No {header} Available (Sub Function Wise)
        </td>
      </tr>
    );
  }

  return (
    <div>
      <table className={style.table}>
        <tbody>
          <tr className={style.theading}>
            <th className={style.head1}>Break-Up (Sub Function Wise)</th>
            <th className={style.head2}>Cost With Overheads</th>
            <th className={style.head2}>Cost Without Overheads</th>
          </tr>
          {tableBody}
          <tr className={style.lastRow}>
            <td className={style.lastRowHead}>Total Cost</td>
            <td className={style.lastRowHead} style={{ textAlign: "center" }}>
              {totalWithOverhead
                ? roundToZeroDecimalPlaces(totalWithOverhead)
                : ""}
            </td>
            <td className={style.lastRowHead} style={{ textAlign: "center" }}>
              {totalWithoutOverhead
                ? roundToZeroDecimalPlaces(totalWithoutOverhead)
                : ""}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function BreakUpResource({ header, data }) {
  let totalWithOverhead = 0,
    totalWithoutOverhead = 0;
  const tableBody =
    !data || data?.length === 0 ? (
      <tr className={style.tRow}>
        <td colSpan={10} style={{ textAlign: "center", padding: "10px" }}>
          No {header} Available (Resource Wise)
        </td>
      </tr>
    ) : (
      data?.map((item, index) => {
        totalWithOverhead += item?.resourceCostWithOverhead || 0;
        totalWithoutOverhead += item?.resourceCostWithoutOverhead || 0;
        return (
          <tr key={index + item?.empId} className={style.row}>
            {index === data.length - 1 ? (
              <>
                <td className={style.lastHeadResource}>{item?.empName}</td>
                <td className={style.lastValue}>{item?.empDesignation}</td>
                <td className={style.lastValue}>
                  {item?.resourceCostWithOverhead
                    ? roundToZeroDecimalPlaces(item?.resourceCostWithOverhead)
                    : 0}
                </td>
                <td className={style.lastValue}>
                  {item.resourceCostWithoutOverhead
                    ? roundToZeroDecimalPlaces(item?.resourceCostWithoutOverhead)
                    : 0}
                </td>
              </>
            ) : (
              <>
                <td className={style.subHeadResource}>{item?.empName}</td>
                <td className={style.subValue}>{item?.empDesignation}</td>
                <td className={style.subValue}>
                  {item?.resourceCostWithOverhead
                    ? roundToZeroDecimalPlaces(item?.resourceCostWithOverhead)
                    : 0}
                </td>
                <td className={style.subValue}>
                  {item.resourceCostWithoutOverhead
                    ? roundToZeroDecimalPlaces(item?.resourceCostWithoutOverhead)
                    : 0}
                </td>
              </>
            )}
          </tr>
        );
      })
    );
  return (
    <div>
      <table className={style.tableResource}>
        <tbody>
          <tr className={style.theadingResource}>
            <th>Break-Up (Resource Wise)</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <td className={style.head1}>Resource Name</td>
            <td className={style.head2}>Resource Function</td>
            <td className={style.head2}>Cost With Overheads</td>
            <td className={style.head2}>Cost Without Overheads</td>
          </tr>
          {tableBody}
          <tr className={style.lastRow}>
            <td className={style.lastRowHead}>Total Cost</td>
            <td></td>
            <td className={style.lastRowHead} style={{ textAlign: "center" }}>
              {totalWithOverhead
                ? roundToZeroDecimalPlaces(totalWithOverhead)
                : ""}
            </td>
            <td className={style.lastRowHead} style={{ textAlign: "center" }}>
              {totalWithoutOverhead
                ? roundToZeroDecimalPlaces(totalWithoutOverhead)
                : ""}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

const CustomInputClg = React.forwardRef((props, ref) => {
  return (
    <div onClick={props.onClick}>
      <label ref={ref} style={{ padding: "8px 8px 8px 5px", fontWeight: 600 }}>
        {props.value || props.placeholder}
      </label>
      <img
        type="image/svg+xml"
        width="20px"
        style={{ position: "absolute", right: "3%", top: "19%", zIndex: "0" }}
        src={calendarIcon}
        onClick={props.onClick}
        alt=""
      />
    </div>
  );
});

function roundToZeroDecimalPlaces(number) {
  return addCommasPCM(number.toFixed(0));
}
