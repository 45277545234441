import React, { useState } from 'react'
import { FaRegCopy } from 'react-icons/fa'
import style from './CopyEmail.module.css'
let timeoutId;

const CopyEmail = ({email}) => {
    const [copyPop, setCopyPop] = useState(false);
    const [showCopy, setShowCopy] = useState(false);
    const copyEmail = ()=>{
        navigator.clipboard.writeText(email);
        setShowCopy(true);
    }
    
    const displayPopup = ()=>{
        timeoutId = setTimeout(() => {
            setCopyPop(true)
        }, 500);
        
    }
    const removePopup = ()=>{
        clearTimeout(timeoutId)
        setCopyPop(false);
        setTimeout(() => {
            setShowCopy(false)
        }, 200);
    }
  return (
    <>{email && <span style={{cursor: "pointer", position: "absolute"}}>
        <p className={style.copyIcon}><FaRegCopy onClick={()=>copyEmail()} onMouseOver={()=>displayPopup()} onMouseOut={()=>removePopup()}/></p><br/>
        <span className={style.popup} style={{display: `${copyPop ? 'flex' : 'none'}`, width: `${showCopy ? '94px' : '85px'}`, transform: `${showCopy ? 'translateX(-30px)' : 'translateX(-25px)'}`}}>{showCopy ? 'Email Copied' : 'Copy Email'}</span>
    </span>}</>
  )
}

export default CopyEmail;

export const CopyMobile = ({number}) => {
    const [copyPop, setCopyPop] = useState(false);
    const [showCopy, setShowCopy] = useState(false);
    const copyEmail = ()=>{
        navigator.clipboard.writeText(number);
        setShowCopy(true);
    }
    
    const displayPopup = ()=>{
        timeoutId = setTimeout(() => {
            setCopyPop(true)
        }, 500);
        
    }
    const removePopup = ()=>{
        clearTimeout(timeoutId)
        setCopyPop(false);
        setTimeout(() => {
            setShowCopy(false)
        }, 200);
    }
  return (
    <>{number && <span style={{cursor: "pointer", position: "absolute"}}>
        <p className={style.copyIcon}><FaRegCopy onClick={()=>copyEmail()} onMouseOver={()=>displayPopup()} onMouseOut={()=>removePopup()}/></p><br/>
        <span className={style.popup2} style={{opacity: `${copyPop ? '1' : 0}`, width: `${showCopy ? '60px' : '46px'}`, transform: `${showCopy ? 'translateX(-12px)' : 'translateX(-8px)'}`}}>{showCopy ? 'Copied!' : 'Copy'}</span>
    </span>}</>
  )
}