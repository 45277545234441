import React, { useEffect, useState } from "react";
import axios from "axios";
import style from "./SignIn.module.scss";
import { GoogleLogin } from "react-google-login";
import landingImage from "./../../assets/landingPage@2x.png";
import Logo from "./../../assets/innovationm-navbar-logo@2x copy.png";
import LogoDark from "./../../assets/innovationm-navbar-logo@2x.png";
import cookie from "react-cookies";
import { useHistory } from "react-router-dom";
import constants, { localStorageGetItem } from "../../Utils/constants";
import secureLocalStorage from "react-secure-storage";
import { localStorageSetItem } from "../../Utils/constants";

const baseUrl = constants.apiBaseUrl;

function SignIn() {
  let history = useHistory();

  useEffect(() => {
    if (cookie.load("token") && cookie.load("companyId")) {
      window.location = "/dashboard";
    }
  }, []);
  const [errormsg, setErrorMsg] = useState("");
  function processJSONObjects(jsonArray) {
    // const cacheKey = JSON.stringify(jsonArray);
    // secureLocalStorage?.setItem("cacheKey", jsonArray);
    // const data = secureLocalStorage?.getItem("cacheKey");
    // if (data === cacheKey) {
    //   return JSON.parse(cacheKey);
    // }
    return secureLocalStorage?.setItem("cacheKey", jsonArray);
  }
  const getUserInfo = (empId) => {
    axios
      .get(baseUrl + `/getUserApplications?userId=${empId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        let data = res.data.responseData.applications;
        processJSONObjects(data?.map((item) => item.applicationCode));
      })
      .catch((err) => {
        return err;
      });
  };
  const responseGoogle = (response, error) => {
    if (!response) {
      alert("error");
    }
    // localStorage.clear();
    // localStorage.setItem("name", response.profileObj?.name);
    localStorageSetItem("name", response.profileObj?.name);
    // localStorage.setItem("email", response.profileObj?.email);
    localStorageSetItem("email", response.profileObj?.email);
    // localStorage.setItem("googleId", response.profileObj?.googleId);
    localStorageSetItem("googleId", response.profileObj?.googleId);

    cookie.save("profile", response?.profileObj);
    cookie.save("googleId", response?.profileObj?.googleId);

    const url = constants.apiBaseUrl;
    const data = {
      email: localStorageGetItem("email"),
      name: localStorageGetItem("name"),
      token: localStorageGetItem("googleId"),
    };

    axios
      .post(url + "/signin", data)
      .then((res) => {
        if (res.status === 200) {
          localStorageSetItem("employeeId", res.data.responseData.employeeId);
          getUserInfo(res.data.responseData.employeeId);

          if (res.data.responseData.signInSuccessful) {
            const resdataObj = {
              Authorization: `${res.headers["authorization"]}`,
            };
            cookie.remove("token");
            cookie.remove("companyId");
            cookie.save("token", resdataObj.Authorization);
            console.log("res.data.responseData.companyId", res.data.responseData.companyId);
            cookie.save("companyId", res.data.responseData.companyId);
            history.push("/dashboard");
            window.location.reload();
          }
          if (res.data.message) {
            setErrorMsg("User not Registered with application");
          }
        }
        return res;
      })
      .catch((err) => {
        history.push("/dashboard");
        return err;
      });
  };

  return (
    <div className={style.container}>
      <div className={style.image}>
        <img src={landingImage} alt="Login" />
      </div>
      <div className={style.content}>
        <img src={Logo} alt="logo" className={style.logo} />
        <img src={LogoDark} alt="logo" className={style.logoDark} />
        <img src={landingImage} alt="Login" className={style.landingImage} />
        <h1>IM Portal Apps</h1>
        <p className={style.subText}>
          Sign In using your Registered Google Account
        </p>

        <GoogleLogin
          clientId={constants.googleClientId}
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={"single_host_origin"}
          render={(renderProps) => (
            <button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              className={style.googleButton}
            ></button>
          )}
        />
        <p className={style.errormsg}>&nbsp;{errormsg}</p>
      </div>
    </div>
  );
}

export default SignIn;
